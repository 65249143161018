.culture {
  // --------------------------------------------------
  // About
  // --------------------------------------------------
  .culture-about {
    background: url('/img/site/shape-pattern-3.png');
    background-position: left bottom;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }




  .block-items-slider {
    .items-main {
      width: 30% !important;
      margin: 50px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .carousel-cell-title {
      position: absolute;
      top: -65px;
      left: 50%;
      transform: translate(-50%,0);
    }
    .carousel-cell-logo {
      display: none !important;
    }
    .carousel-cell-text {
        @extend .regular-text;
        font-size: 15px;
    }
  }
}
