.brands {
	.site-form {
		@media screen and (max-width: 899px) {
			padding: 0 20px;
		}

		.form-select-wrapper {
	    margin: 45px auto;
			margin-top: 0;
			margin-bottom: 0;
			@media screen and (max-width: 899px) {
				margin: 20px auto;
			}
		}

		.form-input {
			@media screen and (max-width: 899px) {
				margin-bottom: 0;
			}
		}
	}

  .content-pagination {
    padding-top: 0;
  }
}

.block-items-grid {
	background: white;

	.items-grid-paginate {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 auto;
    align-items: center;

		.items-carousel-cell {
			margin-bottom: 50px;

			&:hover {
				.cell-effect-top,
				.cell-effect-bottom {
					opacity: 1;
					transform: translate( 0, 0);
				}
			}
		}
	}
}

.brands-pagination {
	margin: -75px auto 50px;

	.brands-page {
		width: 150px;
		display: inline-block;
		height: 10px;
		margin: 0 35px;
		border-radius: 2px;
		transform: skew(40deg);
		box-shadow: 5px 0px 25px 10px rgba(38, 38, 38, 0);
		background: #333;
		opacity: .25;
		transition: all .3s ease;

		&.is-current {
			background: $colorAccent1;
			opacity: 1;
		}
	}

	a {
		&:hover {
			background: $colorAccent2;
			opacity: 1;
		}
	}
}
