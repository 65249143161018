.step {
  padding: 0 50px;
  border-bottom: 1px solid $grey;

  @media (max-width: 1024px) {
    padding: 0 35px;
  }

  .shadow-title {
    position: absolute;
    top: auto;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200%;
    margin: 0;
    padding: 0;
    text-align: center;
    letter-spacing: .1em;
    text-transform: uppercase;
    font-size: 330px;
    font-weight: 100;
    font-family: $fontTitleAlternate;
    // color: #fff;

    background: -webkit-linear-gradient(#fff, rgba(255, 255, 255, .15));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 1149px) {
      font-size: 202px;
      letter-spacing: .05em;
      bottom: 50px;
    }
  }

  .step-wrap {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }

  .step-time {
    @extend .regular-text;
    margin-bottom: 15px;

    @media (max-width: 699px) {
      margin-bottom: 10px;
    }
  }

  .step-title {
    @extend .big-title;
    margin-bottom: 15px;
    color: rgba(0, 0, 0, .15);
    font-size: 95px;
    margin: 0 0 0 0;

    @media (max-width: 699px) {
      font-size: 40px;
      margin-bottom: 10px;
    }
  }
  .step-subtitle {
    @extend .medium-title;
    color: $colorAccent2;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 25px;
    font-family: $fontText;
    margin-top: 10px;
    margin-bottom: 10px;

    @media (max-width: 699px) {
      margin-bottom: 0;
      font-size: 18px;
    }
  }
  .step-desc {
    @extend .regular-text;
    font-size: 18px;

    a {
      color: $colorAccent1;
      border-bottom: 1px solid transparent;
      transition: all .3s ease-in-out;

      &:hover {
        border-color: $colorAccent1;
      }
    }

    @media (max-width: 699px) {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}

.step-row {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 80px;

  @media (max-width: 699px) {
    flex-direction: column;
    padding-left: 65px;
    padding-bottom: 20px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    // width: 4px;
    height: 99%;
    margin-left: -2px;
    // background-color: $colorAccent2;
    // background-image: url('/img/site/dashed_line.svg');
    border-left: 4px dashed $colorAccent2;

    @media (max-width: 699px) {
      left: 23px;
      margin-left: 0;
    }
  }

  &:first-of-type {
    &:before {
      top: calc(50% - 20px);
      height: calc(60% + 40px);

      @media (max-width: 699px) {
        top: 0;
        height: 100%;
      }
    }
  }

  &:last-of-type {
    &:before {
      height: calc(50% - 40px);

      @media (max-width: 699px) {
        top: 0;
        height: 100%;
      }
    }
  }

  .step-row-dot {
    position: relative;
    top: 20px;
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    margin: 0 45px;
    padding: 0;
    margin-left: calc(50% - 50px);
    border: 6px solid $colorAccent2;
    border-radius: 50%;
    background-color: $grey;
    text-align: center;
    z-index: 2;

    transform: scale(0.65);

    @media (max-width: 699px) {
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 50px;
      border-width: 3px;
      margin: 0;
      min-width: inherit;
      max-width: inherit;
      min-height: inherit;
      max-height: inherit;
    }

    .chart {
      position: absolute;
      z-index: 2;
      top: -6px;
      left: -6px;

      @media (max-width: 999px) {
        top: -6px;
        left: -8px;
        transform: scale(.99);
      }

      @media (max-width: 699px) {
        top: -28px;
        left: -28px;
        transform: scale(.5);
      }
    }

    .chart-segments {
      position: absolute;
      top: -6px;
      left: -6px;
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      border-color: transparent;
      border-radius: 50%;
      border-style: solid;
      border-width: 6px;
      z-index: 3;

      @media all and (-ms-high-contrast:none)
      {
      *::-ms-backdrop,
      & { display: none; } /* IE11 */
      }

      .chart-segment {
        position: absolute;
        top: -6px;
        left: -6px;
        width: calc(100% + 12px);
        height: calc(100% + 12px);
        border-color: transparent;
        border-radius: 50%;
        border-style: solid;
        border-width: 6px;
        z-index: 3;
        -webkit-clip-path: inset(0 48px 50px 49px);

       &:nth-child(1) {border-color: $colorAccent2; transform: rotate(0);}
       &:nth-child(2) {border-color: $colorAccent2; transform: rotate(72deg);}
       &:nth-child(3) {border-color: $colorAccent2; transform: rotate(144deg);}
       &:nth-child(4) {border-color: $colorAccent2; transform: rotate(216deg);}
       &:nth-child(5) {border-color: $colorAccent2; transform: rotate(288deg);}
      }
    }
  }

  .step-row-content {
    width: calc(50% - 60px);

    @media (max-width: 699px) {
      position: relative;
      z-index: 5;
      width: 100%;
    }
  }

  &:nth-child(odd) {

    @media (max-width: 699px) {
      flex-direction: column-reverse;
    }

    .step-row-dot {
      order: 2;
      margin: 0 45px;
      margin-right: calc(50% - 50px);

      @media (max-width: 699px) {
        margin: 0;
        order: 1;
      }
    }

    .step-row-content {
      order: 1;
      text-align: right;

      @media (max-width: 699px) {
        order: 3;
        text-align: left;
      }
    }
    .step-image {
      left: inherit;
      right: 0px;

      @media (max-width: 699px) {
        width: 100%;
        position: relative;
        right: 0;
        transform: none;
      }
    }
  }

  &.active {
    .step-row-dot {
      background-color: $colorAccent1;
    }
  }
  .step-image {
    position: absolute;
    left: 0px;
    bottom: 50%;
    width: 40%;
    max-width: 900px;
    height: 10vw;
    min-height: 220px;
    max-height: 200px;
    // margin-right: 5%;
    // margin-left: 5%;
    border-radius: 10px;
    overflow: hidden;
    transform: skew(15deg) translate(0,50%);

    box-shadow: 0 0px 15px 0 rgba(38, 38, 38, 0.5);

    @media (max-width: 1149px) {
      max-width: 675px;
    }
    @media (max-width: 699px) {
      width: 100%;
      height: 35vw;
      min-height: 135px;
      position: relative;
      right: 0;
      transform: none;
    }
    .bg-img {
      position: absolute;
      top: -45px;
      right: -45px;
      bottom: -45px;
      left: -45px;

      transform: skew(-15deg);

      // background-size: cover;
      background-position: center;

      @media (max-width: 699px) {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: none;
        background-size: cover;
      }
    }
  }
}
