// --------------------------------------------------
// Contact
// --------------------------------------------------
.contact-content {
  @extend .site-max-width;

  padding-top: 25px;
  padding-bottom: 25px;
}

// --------------------------------------------------
// Half content Map
// --------------------------------------------------
.contact-half {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.contact-half-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  // padding: 55px;
  padding-top: 25px;
  padding-bottom: 25px;
  background: #fff;

  @media only screen and (max-width: 1099px) {
    width: 100%;
    padding: 0 !important;
  }

  .big-title {
    font-size: 32px;
    margin-bottom: 25px;
    @media only screen and (max-width: 699px) {
      font-size: 24px;
      padding: 0;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  .regular-text {
    strong {
      font-family: $fontTitleSerif;
      color: $colorAccent3;
      font-size: 20px;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin-top: 25px;
    margin-bottom: 25px;

    @media only screen and (max-width: 1099px) {
      display: inline-block;
      width: auto;
      text-align: left;
      padding: 0 15px 15px 15px;
      margin-top: 10px;
    }

    @media only screen and (max-width: 699px) {
      text-align: center;
    }

    li {
      padding: 10px 0;
      line-height: 1;
      color: $colorAccent3;

      .icon {
        margin-right: 15px;
      }

      a {
        transition: all 0.35s ease;

        i {
          transition: all 0.35s ease;
        }
        &:hover {
          color: $colorAccent1;

          i {
            color: $colorAccent1;
          }
        }
      }
    }
  }

  ul.socials-links {
    li {
      color: $grey;

      .icon {
        color: $grey;
      }

      a {
        transition: all 0.35s ease;

        i {
          transition: all 0.35s ease;
        }
        &:hover {
          color: $colorAccent3;

          i {
            color: $colorAccent3;
          }
        }
      }
    }
  }

  @media (max-width: 1099px) {
    width: 100%;
    padding: 60px 20px;
  }

  @media (max-width: 565px) {
    padding: 40px 20px;
  }
}

.contact-half-addresses,
.address-text {
  @media only screen and (max-width: 1099px) {
    width: 100%;
    text-align: center;
  }
}

.contact-links {
  li span{
    display: flex;
    align-items: center;
    color: inherit;
    cursor: default;

    &:hover {
      color: inherit;
      cursor: default;
    }

    img {
      margin-right: 25px;
    }
  }
}

.contact-half-address {
  display: flex;
  align-items: center;

  .icon {
    color: $colorAccent3;
    font-size: 20px;
  }

  .address-icon {
    margin-right: 60px;
    font-size: 48px;
    color: $colorAccent1;

    @media (max-width: 1099px) {
      margin-right: 40px;
      font-size: 40px;
    }
  }

  .contact-half-title {
    @extend .medium-title;
    margin-bottom: 20px;
    font-size: 16px;
  }

  .contact-half-text, p {
    @extend .regular-text;
    font-size: 16px;
    letter-spacing: 0.065em;
  }
}

.contact-half-map {
  position: relative;
  width: 50%;

  @media only screen and (max-width: 1099px) {
    width: 100%;
    height: 50vw;
  }
}

// --------------------------------------------------
// Content - Map
// --------------------------------------------------
.map-wrapper {
  position: relative;
  width: 100%;
  height: 35vw;

  @media only screen and (max-width: 1099px) {
    width: 100%;
    height: 65vw;
  }

  @media only screen and (max-width: 599px) {
    width: 100%;
    height: 75vw;
  }
}
.content-map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .content-map-wrapper {
    width: 100%;
    height: 100%;
  }
}

// --------------------------------------------------
// Contact Block
// --------------------------------------------------
.contact-block-cta {
  @extend .site-max-width;
  display: flex;
  padding-top: 25px;
  padding-bottom: 25px;

  @media (max-width: 1099px) {
    flex-wrap: wrap;
  }

  .contact-block-content {
    position: relative;
    width: 50%;
    padding: 75px 55px;
    padding-right: 175px;
    color: #fff;

    background: $colorAccent1;
    background-image: url('/img/site/contact-square-ph.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;

    &:before {
      display: none;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $colorAccent1;
      opacity: .60;

      z-index: 1;
    }

    @media (max-width: 1099px) {
      display: none;
    }

    .big-title {
      // max-width: 300px;
      line-height: 1.3;
      font-size: 26px;
      margin-bottom: 25px;
    }

    .regular-text {
      margin-bottom: 35px;
      max-width: 400px;
    }

    .big-title,
    .link-text-btn,
    .regular-text {
      position: relative;
      color: #fff;
      border-color: #fff;
      z-index: 1;
    }

    .site-btn {
      position: relative;
      color: #fff;
      border-color: #fff;

      z-index: 2;
    }
  }

  .contact-block-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    width: calc(100% - 40%);
    padding: 35px 55px;
    padding-right: 0;
    text-align: center;

    @media (max-width: 1099px) {
      width: 100%;
      padding: 0 !important;
    }

    @media (max-width: 767px) {
      padding: 45px 20px;
    }

    &.is-full {
      width: 100%;
    }

    .medium-title {
      display: inline-block;
      margin: 0 auto 35px auto;
      color: $grey;

      &:before,
      &:after {
        background: $grey;
      }
    }

    .contact-block-form {
      display: flex;
      width: 100%;
    }

    .contact-form-wrapper {
      width: 100%;
      max-width: 500px;
    }
  }
}

// Hubspot Extends
.contact {
  .hbspt-form form .hs-form-field.field label {
    display: none;
  }
  .hbspt-form form .hs-form-field.field .hs-error-msgs label {
    display: block;
    top: -12px;
    padding-left: 15px;
    background: rgba(0, 0, 0, .12);
  }
  .hbspt-form form .hs-form-field.field input {
    background: transparent;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, .12);
    margin-bottom: 20px;
  }

  .hbspt-form form .hs-form-field.field textarea {
    background: transparent !important;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, .12);
    margin-bottom: 10px;
  }

  .hubspot-contact .hbspt-form form .hs-button, .hubspot-contact .hbspt-form form .hs-button.primary.large {
    border-radius: 0;
    padding: 20px 55px;
  }

  .hs_submit.hs-submit {
    width: 100%;
    text-align: right;
    margin-bottom: -10px;
  }

  .contact-form {
    padding-top: 0;

    .intro-text {
      text-align: center;
      span {
        color: $colorAccent1;
      }
    }
  }

}


// Google Map Extends
#gm-content {
  padding: 15px;
  border: none;
  border-color: rgba(0, 0, 0, .12);
}

.gm-title {
  font-family: $fontTitle;
  font-size: 16px;
  color: #4A5161;
  max-width: 225px;
  line-height: 1.2;

  @media (max-width: 599px) {
    font-size: 13px;
    max-width: 225px;
    line-height: 1.2;
  }
}

.gm-text {
  max-width: 225px;
  font-family: $fontTextLight;
  color: #90A0B0;
  font-size: 14px;
}

.gm-links {
  display: block;
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  color: #57B8DD;
  font-weight: 800;
  font-size: 12px;
}

// --------------------------------------------------
// Address
// --------------------------------------------------

.contact-address {
  background-color: #fafafa;
  text-align: center;
  padding-bottom: 70px;

  @media(max-width: 940px) {
    padding: 10px 20px;
  }

  .address-head {
    margin-bottom: 20px;

    .office-city {
      margin-top: 0;
    }

  }

  .address-main {
    display: flex;
    justify-content: center;

    @media(max-width: 940px) {
      flex-direction: column;
    }

    .address-office {
      min-width: 340px;

      @media(max-width: 940px) {
        margin-bottom: 20px;
      }

      @media(max-width: 340px) {
        min-width: unset;
      }
    }
  }

  .address-office {
    @extend .regular-text;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 18px;

    @media (max-width: 767px) {
      font-size: 16px;
    }

    .office-address {
      margin-top: 0;
    }

    .office-name {
      @extend .medium-title;

      margin-bottom: 0;

      font-family: $fontText;
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;

      @media (max-width: 767px) {
        font-size: 18px;
      }
    }

    .office-phone,
    .office-mail,
    .office-fax {
      color: inherit;
    }

    .separator {
      display: block;
      height: 4px;
      width: 135px;
      margin: 15px 0;
      padding: 0;
      background-color: $colorAccent2;
    }
  }
}
