.homepage {
  .header-menu {
    left: 0;
  }
}

.section-wrapper {
  @extend .site-max-width;
}

.section-wrapper-content {
  position: relative;
}

.carousel-cell.is-selected {

  img.main {}

  .carousel-cell-content {
    opacity: 1;
  }
}

// --------------------------------------------------
// Value
// --------------------------------------------------
.home-value {
  display: flex;
  position: relative;
  padding: 0 0 25% 0;

  @media (max-width: 899px) {
    flex-direction: column;
  }

  .value-intro {
    width: 50%;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: auto;

    @media (max-width: 899px) {
      width: 100%;
    }

    .value-title {
      text-align: right;
      width: 100%;
      padding: 25px 0;

      @media (max-width: 899px) {
        text-align: center;
      }
    }

    .value-separator {
      align-self: flex-end;

      @media (max-width: 899px) {
        align-self: center;
      }
    }

    .value-content {
      align-self: flex-end;
      width: 60%;
      padding: 25px 0;
      text-align: right;

      @media (max-width: 899px) {
        margin: auto;
        text-align: center;
      }
    }

    .value-cta {
      align-self: flex-end;
      border-radius: 5px;
      transform: skew($skewValue);

      @media (max-width: 899px) {
        align-self: center;
      }

      span {
        display: block;
        transform: skew($skewValueRevert);
      }
    }
  }
  .value-image {
    display: block;

    position: absolute;
    bottom: 0;
    left: 0;

    width: 65%;


    @media (max-width: 899px) {
      position: inherit;
      width: 100%;
    }

    img {
      display: block;

      width: 100%;
      height: auto;

      box-shadow: 5px 0px 25px 10px rgba($dark, .18);
    }
  }
}

// --------------------------------------------------
// Blog Home
// --------------------------------------------------

.home-publications {
  position: relative;
  margin-bottom: 0;
  border-bottom: 50px solid $grey;

  padding-bottom: 30vw;

  @media (max-width: 1149px) {
    border-bottom: 0;
  }

  .shadow-title {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50px);
    width: 200%;
    margin: 0;
    padding: 0;
    text-align: center;
    letter-spacing: .090em;
    text-transform: uppercase;
    font-size: 300px;
    font-weight: 100;
    font-family: $fontTitleAlternate;

    z-index: 1;

    background: -webkit-gradient(linear, left top, left bottom, from(white), to(rgba(255, 255, 255, .15)));
    background: -webkit-linear-gradient(white, rgba(255, 255, 255, .15));
    background: -o-linear-gradient(white, rgba(255, 255, 255, .15));
    background: linear-gradient(white, rgba(255, 255, 255, .15));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: $white;

    @media (max-width: 1149px) {
      font-size: 202px;
      letter-spacing: .05em;
      bottom: 50px;
    }
  }

  .split-text-image-slider {
    z-index: 2;
  }


}

.blog-carousel {
  position: relative;
  // display: block;
  // transform: skew($skewValue);

  .blog-carousel-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    right: 50%;

    width: 65%;

    transform: translate(50%,-50%);

    .carousel-content-title {

      align-self: flex-end;
      color: $white;
      text-align: right;
      text-transform: uppercase;

      transform: skew($skewValue);
    }
    .carousel-content-cta {
      border-radius: 5px;
      border: none;
      transform: skew(42deg);
      width: 40%;
      margin-right: 0;
      align-self: flex-end;

      span {
        display: block;
        transform: skew($skewValueRevert);
      }
    }
  }
  .blog-carousel-image {
    .carousel-image {
      width: 100%;
    }
  }
}

// --------------------------------------------------
// Newsletter
// --------------------------------------------------
.newsletter-cta {
  display: flex;
  padding: 5px 0 10px 0;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }

  .newsletter-content {
    position: relative;
    width: 60%;
    padding: 75px 55px;
    padding-right: 175px;
    color: #fff;

    background: $colorAccent1;
    background-image: url('/img/site/img-ph1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;

    @media only screen and (max-width: 1349px) {
      padding: 75px 45px;
    }

    @media only screen and (max-width: 1099px) {
      padding: 75px 25px;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 35px 20px;
      text-align: center;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $colorAccent1;
      opacity: .60;

      z-index: 1;
    }

    .big-title {
      // max-width: 300px;
      line-height: 1.3;
      font-size: 26px;
      margin-bottom: 25px;

      @media only screen and (max-width: 767px) {
        font-size: 24px;
      }
    }

    .regular-text {
      margin-bottom: 35px;
      max-width: 400px;

      @media only screen and (max-width: 767px) {
        max-width: inherit;
      }
    }

    .big-title,
    .link-text-btn,
    .regular-text {
      position: relative;
      color: #fff;
      border-color: #fff;
      z-index: 1;
    }

    .site-btn {
      position: relative;
      color: #fff;
      border-color: #fff;

      z-index: 2;

      &:hover {
        background: #fff;
        color: $colorAccent1;
      }
    }
  }

  .newsletter-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    width: calc(100% - 60%);
    padding: 65px 55px;
    background: $alternateWhite;
    text-align: left;

    @media only screen and (max-width: 1349px) {
      padding: 65px 45px;
    }

    @media only screen and (max-width: 1099px) {
      padding: 65px 25px;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 35px 20px;
      text-align: center;

      .newsletter-component {
        width: 100%;
      }
    }

    .big-title {
      font-family: $fontTextLight;
      width: 100%;
      font-weight: 400;
      letter-spacing: 0;
      font-size: 32px;
      line-height: 1.3;

      @media only screen and (max-width: 767px) {
        font-size: 26px;
        padding: 0;
        margin: 0 0 25px 0;
      }

      img {
        width: 35px;
      }
    }

    .newsletter-form {
      display: flex;
      width: 100%;

      input {
        width: 100%;
        background: #fff;
        // border: 2px solid $colorAccent1;
        border: none;
        color: $colorAccent1;
        // border-radius: 3px;
        padding: 15px 20px;
        outline: none;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.02em;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        box-shadow: 0 0 35px 0 rgba(221, 221, 221, .18);


      }

      button {
        padding: 15px 35px;
        // border-radius: 3px;
        background: $colorAccent1;
        letter-spacing: 0.055em;
        font-family: $fontTitleSerif;
        font-size: 16px;
        color: #fff;
        border: 1px solid $colorAccent1;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        cursor: pointer;

        transition: all 0.35s ease;

        &:hover {
          background: $colorAccent3;
          color: #fff;
          border-color: $colorAccent3;
        }
      }
    }

    .hbspt-form form {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .hs-button,
      .hs-button.primary.large {
        position: relative;
        top: 2px;
        left: -3px;
        padding: 15px 35px;
        margin: 0;
        // border-radius: 3px;
        background: $colorAccent1;
        letter-spacing: 0.055em;
        font-family: $fontTitleSerif;
        font-size: 16px;
        color: #fff;
        border: 1px solid $colorAccent1;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        cursor: pointer;

        transition: all 0.35s ease;

        &:hover {
          background: $colorAccent3;
          color: #fff;
          border-color: $colorAccent3;
        }
      }

      .hs_error_rollup {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translate3d( 0, 100%, 0);
      }

      .hs-error-msgs.inputs-list {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translate3d( 0, 100%, 0);
        line-height: 1;
      }
    }
  }
}
