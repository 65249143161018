// --------------------------------------------------
// Footer
// --------------------------------------------------

.footer-top {
  display: flex;
  position: relative;
  background-color: $colorAccent2;
  align-self: flex-start;

  @media (max-width: 899px) {
    flex-direction: column;
  }

  .footer-top-title {
    @extend .site-padding;

    width: 60%;
    background: url('/img/site/ph1-hero.jpg');
    background-size: cover;
    background-position: center;
    margin-left: 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 30%;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, .45);
    }

    @media (max-width: 899px) {
      width: 100%;

      &:before {
        right: 0;
      }
    }

    .title {
      position: relative;
      max-width: 100%;
      max-width: 430px;
      color: #fff;
      font-family: $fontTitleAlternate;
      font-weight: 400;
      font-size: 40px;
      letter-spacing: 0.0em;
      line-height: 1.1;
      text-transform: uppercase;

      z-index: 2;

      @media (max-width: 899px) {
        max-width: 100%;
        text-align: center;
        font-size: 35px;
      }

      @media (max-width: 699px) {
        font-size: 23px;
        max-width: 300px;
        margin: 0 auto;
        padding: 20px 0;
      }
    }
  }

  .footer-top-aside {
    @extend .site-max-width;

    display: flex;
    align-items: center;
    width: 50%;
    background-color: $colorAccent2;
    position: absolute;
    right: 0;
    height: 100%;
    transform: skew(-21deg);

    @media (max-width: 899px) {
      align-items: inherit;
      transform: none;
    }

    @media (max-width: 899px) {
      position: inherit;
      width: 100%;
      padding-top: 25px;
      padding-bottom: 25px;
    }

    .aside-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      transform: skew(21deg);

      @media (max-width: 899px) {
        flex-direction: column;
        transform: none;
        text-align: center;
      }

      .footer-text {
        width: 100%;
        // margin-right: 50px;
        margin-left: 50px;
        padding-right: 100px;
        color: #fff;
        font-size: 17px;
        line-height: 1.6;

        @media (max-width: 1399px) {
          padding-right: 50px;
          margin: 0;
        }

        @media (max-width: 899px) {
          padding: 0;
          margin-top: -15px;
        }

        p a {
          color: $colorAccent1;
          transition: all 0.35s ease;

          &:hover {
            color: $colorAccent2;
          }
        }
      }

      .newsletter-sub {
        width: 100%;
        margin-right: 50px;
        margin-left: 50px;
        background-color: $colorAccent2;
        border: none;
        border-bottom: 1px solid #fff;
        text-transform: uppercase;
        outline: $colorAccent1;
        color: #fff;

        @media (max-width: 899px) {
          width: 100%;
          max-width: 500px;
          margin: 0 auto;
          text-align: center;
          line-height: 1.5;
          height: 50px;
        }

        @media (max-width: 899px) {
          margin-bottom: 25px;
          margin-top: -10px;
        }
      }

      .site-btn {
        min-width: 180px;
        padding-top: 15px;
        padding-bottom: 15px;

        font-family: $fontText;
        font-size: 14px;
        color: $colorAccent1;
        background-color: $white;
        border: none;

        border-radius: 8px;
        transform: skew($skewValue);

        letter-spacing: 0.055em;
        text-transform: uppercase;

        @media (max-width: 899px) {
          transform: none;
          max-width: 200px;
          margin: 0 auto;
        }

        .btn-text {
          display: block;
          transform: skew($skewValueRevert);

          @media (max-width: 899px) {
            transform: none;
          }
        }

        &:hover {
          background: #fff;
          color: $colorAccent2;
        }


        @media (max-width: 1099px) {
          padding: 15px 25px;
        }

        @media (max-width: 899px) {
          width: 100%;
        }

        span {
          display: block;
          transform: skew(-21deg);

          @media (max-width: 899px) {
            transform: none;
          }
        }
      }
    }
  }
}

.main-footer {
  position: relative;
  background: $grey;
  color: $alternateGrey;
  font-family: $fontText;
  font-weight: 600;

  z-index: 1;

  .socials {
    position: absolute;
    top: 40px;
    right: 0;
    font-size: 20px;

    a {
      margin-right: 0;

      @media only screen and (max-width: 1349px) {
        font-size: 20px;
      }
    }
  }

  .logo {
    position: relative;
    bottom: 0;
    left: 0;
    transform: translate3d(0, 5px, 0);
    padding: 0;
    margin: 0;
    margin-right: auto;

    img {
      max-width: 175px;
    }
  }

  .social-icons {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(0, -50%);

    i {
      font-size: 30px;
      color: $colorAccent2;
      margin: 0;

      &:before {
        margin: 0;
      }
    }
  }
}

.footer-nav {
  @extend .site-padding;

  position: relative;
  padding-top: 45px;
  padding-bottom: 25px;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, .08);

  @media only screen and (max-width: 1349px) {
    // padding: 45px 0 25px 0;
    padding-top: 45px;
    padding-bottom: 25px;
  }

  @media only screen and (max-width: 899px) {
    display: none;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  a {
    display: inline-block;
    font-family: $fontTextLight;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0 20px;
    color: $colorAccent3;

    transition: all 0.35s ease;

    &:hover {
      color: $colorAccent2;
    }

    &:last-child {
      margin-right: 0;
    }

    @media only screen and (max-width: 1349px) {
      margin-right: 25px;
      font-size: 14px;
    }

    @media only screen and (max-width: 899px) {
      margin-right: 15px;
      margin-left: 15px;
      line-height: 1.5;
    }
  }

  .footer-nav-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.footer-copy {
  @extend .site-padding;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 25px;
  font-family: $fontText;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.035em;
  font-weight: 300;
  text-transform: uppercase;
  color: $colorAccent3;

  @media only screen and (max-width: 1199px) {
    padding-bottom: 25px;
  }

  @media only screen and (max-width: 899px) {
    flex-direction: column;
    text-align: center;
    line-height: 1.3;
  }

  a {
    display: inline-block;
    font-family: $fontText;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 1px;
    font-weight: 300;
    margin: 0 20px;
    text-transform: uppercase;
    color: $colorAccent3;

    transition: all 0.35s ease;

    &:hover {
      color: $colorAccent2;
    }

    @media only screen and (max-width: 1349px) {
      padding-right: 25px;
    }

    @media only screen and (max-width: 899px) {
      display: none;

      &:nth-child(4),
      &:nth-child(5) {
        display: inline-block;
      }
    }

    &:last-child {
      padding-right: 0;
      margin-right: 0;
    }
  }
}
