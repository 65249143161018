// --------------------------------------------------
// Block Globals
// --------------------------------------------------

.site-block {
}

// --------------------------------------------------
// Block - Introduction
// --------------------------------------------------

.block-introduction {
  @extend .section-padding;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .intro-title {
    @extend .big-title;
    max-width: 1024px;
    line-height: 1.1;
    margin: 0;
    padding: 0;
    text-align: center;
    color: $colorAccent2;
  }

  .intro-separator {
    display: block;
    height: 4px;
    width: 135px;
    margin: 35px 0;
    padding: 0;
    background-color: $colorAccent1;

    @media (max-width: 979px) {
      margin: 25px 0;
    }
  }

  .intro-content {
    @extend .regular-text;

    width: 100%;
    max-width: 475px;
    margin: 0 0 10px 0;
    padding: 0;
    text-align: center;

    @media (max-width: 1070px) {
      width: 60%;
    }

    @media (max-width: 599px) {
      width: 100%;
    }
    p {
      a {
        color: $colorAccent1;
      }
    }
  }
  .intro-cta {
    @extend .site-btn;
  }
}

// --------------------------------------------------
// Featured Slider
// --------------------------------------------------

.block-featured-slider {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 200px;
  z-index: 9;
  border-bottom: 5px solid #fff;
  background: url('/img/site/home-intro-bg.jpg');
  background-size: cover;
  background-position: center bottom 0;
  background-repeat: no-repeat;

  @media (max-width: 1149px) {
    padding-bottom: 100px;
  }

  .block-introduction {
    position: relative;
    background: transparent;
  }

  .main-carousel {
    position: relative;

    .carousel-cell {
      position: relative;
      width: 100%;
      max-width: 900px;
      height: 20vw;
      min-height: 300px;
      max-height: 400px;
      margin-right: 5%;
      margin-left: 5%;
      border-radius: 10px;
      overflow: hidden;
      transform: skew($skewValue);

      @media (max-width: 1149px) {
        max-width: 675px;
      }

      @media (max-width: 899px) {
        transform: none;
        border-radius: 0;
        max-width: 500px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #333;
        z-index: 5;
        opacity: .50;
      }
    }

    .carousel-cell-content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 6;

      padding: 45px;
      text-align: center;
      color: #fff;

      transition: all 0.45s ease;

      overflow: hidden;

      transform: skew($skewValueRevert);

      @media (max-width: 899px) {
        transform: none;
      }

      @media only screen and (max-width: 767px) {
        padding: 45px 20px;
      }

      &:before {
        display: none;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // background: $colorAccent1;
        z-index: 5;
        opacity: .90;
      }

      * {
        position: relative;
        z-index: 6;
      }

      .separator-content-wrapper {
        @extend .site-padding;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);

        text-align: right;
      }
    }

    .carousel-cell-title {
      position: relative;
      text-transform: uppercase;
      font-family: $fontTitleAlternate;
      font-weight: 800;
      font-size: 43px;
      color: $white;
      letter-spacing: 0.1em;
      z-index: 6;

      @media (max-width: 1149px) {
        font-size: 35px;
      }

      @media (max-width: 899px) {
        font-size: 28px;
      }

      @media (max-width: 699px) {
        font-size: 23px;
      }
    }


    .site-btn {
      position: relative;

      padding: 13px 25px;

      font-family: $fontText;
      font-size: 14px;
      color: $colorAccent1;
      background-color: $white;
      border: none;

      border-radius: 8px;
      transform: skew($skewValue);

      letter-spacing: 0.055em;
      text-transform: uppercase;

      z-index: 6;

      @media (max-width: 899px) {
        transform: none;
      }

      .btn-text {
        display: block;
        transform: skew($skewValueRevert);

        @media (max-width: 899px) {
          transform: none;
        }
      }

      &:hover {
        background: #fff;
        color: $colorAccent1;
      }
    }

    .bg-img {
      position: absolute;
      top: -100px;
      right: -100px;
      bottom: -100px;
      left: -100px;

      transform: skew($skewValueRevert);

      background-size: cover;
      background-position: center;

      @media (max-width: 899px) {
        transform: none;
      }
    }

    .flickity-prev-next-button {
      border-radius: 5px;
      transform: skew($skewValue);
      background-color: $white;

      @media (max-width: 899px) {
        transform: none;
      }

      &.previous {
        width: auto;
        height: auto;
        left: -10px;
        bottom: 50%;
        right: auto;
        top: auto;
        transform: translate(0, 50%) skew($skewValue);

        padding: 30px 40px;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        box-shadow: 0 0px 20px 0 rgba(38, 38, 38, 1);

        z-index: 5;

        @media (max-width: 899px) {
          transform: translate(0, 50%);
        }

        @media (max-width: 699px) {
          bottom: -60px;
          transform: scale(.65) translate(-10%, 50%);
          box-shadow: none;
          border: 1px solid rgba(0, 0, 0, .18);
        }

        svg {
          transform: scale(.5);
          path {
            d: path('M 5,50 97.5,5 97.5,95 Z');
          }
        }
      }

      &.next {
        width: auto;
        height: auto;
        top: auto;
        left: auto;
        right: -10px;
        bottom: 50%;
        transform: translate(0, 50%) skew($skewValue);

        padding: 30px 40px;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        box-shadow: 0 0px 20px 0 rgba(38, 38, 38, 1);

        z-index: 5;

        @media (max-width: 899px) {
          transform: translate(0, 50%);
        }

        @media (max-width: 699px) {
          bottom: -60px;
          transform: scale(.65) translate(10%, 50%);
          box-shadow: none;
          border: 1px solid rgba(0, 0, 0, .18);
        }

        svg {
          transform: scale(.5);
          path {
            d: path('M 5,50 97.5,5 97.5,95 Z');
          }
        }
      }

      .flickity-button-icon {
        fill: $colorAccent1;
      }
    }

    .flickity-page-dots {
      bottom: -100px;

      @media (max-width: 1149px) {
        bottom: -60px;
      }

      .dot {
        width: 150px;
        height: 7px;
        margin: 0 35px;
        border-radius: 2px;
        transform: skew(40deg);

        box-shadow: 5px 0px 25px 10px rgba($dark,.18);

        &.is-selected {
          background-color: $colorAccent1;
        }

        @media (max-width: 1149px) {
          width: 100px;
          margin: 0 20px;
        }

        @media (max-width: 699px) {
          width: 30px;
          height: 3px;
          border-radius: 0;
          transform: none;
          margin: 0 10px;
          box-shadow: none;
        }
      }
    }
  }
}

// --------------------------------------------------
// Block - Items Slider
// --------------------------------------------------

.block-items-slider,
.block-items-grid {
  position: relative;
  @extend .section-padding;
  background-color: $grey;
  text-align: center;

  @media (max-width: 1149px) {
    padding-left: 0;
    padding-right: 0;
  }

  .shadow-title {
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -20%);
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    letter-spacing: .2em;
    text-transform: uppercase;
    font-size: 400px;
    font-family: $fontTitleAlternate;
    color: #fff;

    z-index: 0;

    @media (max-width: 1149px) {
      font-size: 302px;
    }

    @media (max-width: 899px) {
      display: none;
    }
  }

  .block-introduction {
    background: transparent;
    padding-top: 0;
  }

  .items-carousel,
  .items-grid-paginate {
    position: relative;
    padding-bottom: 40px;
    z-index: 1;

    @media (max-width: 899px) {
      padding-bottom: 40px;
    }

    .flickity-prev-next-button {
      border-radius: 5px;
      transform: skew($skewValue);
      background-color: $white;

      @media (max-width: 899px) {
        transform: none;
      }

      &.previous {

        width: auto;
        height: auto;
        left: -90px;
        bottom: 50%;
        right: auto;
        top: auto;

        padding: 30px 40px;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        box-shadow: 0 0px 20px 0 rgba(38, 38, 38, 0.12);

        @media (max-width: 1299px) {
          left: -50px;
        }

        @media (max-width: 1149px) {
          left: -10px;
        }

        @media (max-width: 699px) {
          // bottom: -60px;
          transform: scale(.65) translate(-10%, 50%);
          box-shadow: none;
          border: 1px solid rgba(0, 0, 0, .18);
        }

        svg {
          transform: scale(.5);
          path {
            d: path('M 5,50 97.5,5 97.5,95 Z');
          }
        }
      }

      &.next {
        width: auto;
        height: auto;
        top: auto;
        left: auto;
        right: -90px;
        bottom: 50%;

        padding: 30px 40px;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        box-shadow: 0 0px 20px 0 rgba(38, 38, 38, 0.12);

        @media (max-width: 1299px) {
          right: -50px;
        }

        @media (max-width: 1149px) {
          right: -10px;
        }

        @media (max-width: 699px) {
          // bottom: -60px;
          transform: scale(.65) translate(10%, 50%);
          box-shadow: none;
          border: 1px solid rgba(0, 0, 0, .18);
        }

        svg {
          transform: scale(.5);
          path {
            d: path('M 5,50 97.5,5 97.5,95 Z');
          }
        }
      }

      .flickity-button-icon {
        fill: $colorAccent1;
      }
    }

    .items-carousel-cell {
      position: relative;
      width: 100%;
      max-width: 275px;
      margin: 0 7%;
      padding: 5px;
      display: flex;
      flex-direction: column;

      border-radius: 10px;
      overflow: hidden;

      @media (max-width: 899px) {
        max-width: 225px;
        margin: 0 5%;
      }

      @media (max-width: 699px) {
        width: 60%;
        max-width: 265px;
        margin: 0 10%;
      }

      .cell-effect-top {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-10px, 10px);
        width: 90%;
        height: 200px;
        border-radius: 10px;
        background: $colorAccent2;
        z-index: 2;

        // opacity: 0;
        transition: all 0.65s ease 0.25s;
      }

      .cell-effect-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(10px, -10px);
        width: 90%;
        height: 200px;
        border-radius: 10px;
        background: $colorAccent1;
        z-index: 2;

        // opacity: 0;
        transition: all 0.65s ease 0.25s;
      }

      &.is-selected {
        .cell-effect-top,
        .cell-effect-bottom {
          opacity: 1;
          transform: translate( 0, 0);
        }
      }

      .items-carousel-cell-wrapper {
        position: relative;
        z-index: 5;
        background: #fff;
        border-radius: 5px;
        overflow: hidden;

        box-shadow: 0 0px 10px 0 rgba(38, 38, 38, 0.18);


      }
      .items-carousel-homemade {
        display: none;
        &.is-homemade {
          display: block;
          position: absolute;
          top: -10px;
          right: -10px;
          width: 100px;
          height: 100px;
        }
      }
      .items-main {
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        // height: 200px;
      }

      .items-content-wrapper {
        width: 100%;
        padding: 15px;

        display: block;
        margin: auto;
        align-items: center;

        .items-content-border {

          position: relative;
          padding: 25px 0;
          border-radius: 5px;
          border: 1px solid rgba(0, 0, 0, .15);
          min-height: 165px;



          .border-corner {
            position: absolute;
            width: 25px;
            height: 25px;
            background: #fff;
            z-index: 1;

            &.tl {
              top: 0;
              left: 0;
              transform: translate(-50%, -50%);
            }

            &.tr {
              top: 0;
              right: 0;
              transform: translate(50%, -50%);
            }

            &.bl {
              bottom: 0;
              left: 0;
              transform: translate(-50%, 50%);
            }

            &.br {
              bottom: 0;
              right: 0;
              transform: translate(50%, 50%);
            }
          }
        }

        .carousel-cell-logo {
          display: block;
          width: auto;
          // min-width: 140px;
          height: auto;
          // min-height: 90px;
          margin: 0 auto;

          @media (max-width: 699px) {
            width: auto;
          }
        }

        .carousel-cell-text {
          @extend .regular-text;
        }

        .carousel-cell-title {
          display: block;
          margin: 25px 0 0 0;
          padding: 0;
          font-family: $fontText;
          color: $colorAccent2;
          font-size: 16px;
          text-align: center;
          text-transform: uppercase;
        }

      }
    }
  }

  .items-cta {
    border-radius: 5px;
    display: inline-block;
    width: auto;
    margin: 0 auto 5px auto;
    transform: skew($skewValue);

    @media (max-width: 899px) {
      transform: none;
    }

    span {
      display: block;
      transform: skew($skewValueRevert);

      @media (max-width: 899px) {
        transform: none;
      }
    }
  }
}

// --------------------------------------------------
// Block split-text-image
// --------------------------------------------------

.split-text-image {
  @extend .section-padding;

  display: block;
  // max-height: 1000px;
  position: relative;
  padding-top: 25px;
  // margin-bottom: 100px;
  padding-bottom: 30vw;

  @media (max-width: 1149px) {
    padding-bottom: 18vw;
  }

  @media (max-width: 899px) {
    padding-bottom: 20px;
  }

  @media (min-width: 2149px) {
    padding-bottom: 400px;
  }

  &.pattern1 {
    background: url('/img/site/shape-pattern-1.png');
    background-position: left bottom;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  &.pattern2 {
    background: url('/img/site/shape-pattern-2.png');
    background-position: right bottom;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  &.pattern3 {
    background: url('/img/site/shape-pattern-3.png');
    background-color: #eee;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &.text-heavy {
    padding-bottom: 10vw;
  }

  &.text-low {
    padding-bottom: 27vw;
  }

  .split-text-image-content {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    width: 100%;

    z-index: 2;

    .split-text-image-title {
      @extend .big-title;
      margin: 0;
      padding: 0;
      color: $colorAccent2;
      width: 100%;
      // max-width: 1080px;

      @media (max-width: 1149px) {
        max-width: 600px;
      }

      @media (max-width: 899px) {
        width: 100%;
        max-width: inherit;
        text-align: center;
      }
    }

    .split-text-image-separator {
      display: block;
      height: 4px;
      width: 135px;
      margin: 30px 0;
      padding: 0;
      background-color: $colorAccent1;

      @media (max-width: 979px) {
        margin: 25px 0;
      }

      @media (max-width: 899px) {
        align-self: center;
      }
    }

    .split-text-image-text {
      @extend .regular-text;

      width: 100%;
      margin: 0;
      padding: 0;
      width: 100%;
      max-width: 430px;

      @media (max-width: 1149px) {
        max-width: 300px;
      }

      @media (max-width: 899px) {
        margin: auto;
        text-align: center;
      }

      &.full {
        max-width: 100%;

        @media (max-width: 1149px) {
          max-width:100%;
        }
      }

      ul,
      ul li {
        @media (max-width: 899px) {
          list-style-type: none;
          padding: 0;
        }
      }
    }

    .site-btn {
      margin-top: 45px;
      align-self: flex-start;
      transform: skew($skewValue);

      @media (max-width: 899px) {
        align-self: center;
        transform: none;
      }

      span {
        display: block;
        transform: skew($skewValueRevert);

        @media (max-width: 899px) {
          transform: none;
        }
      }
    }
  }

  .split-text-image-image {
    display: block;

    position: absolute;
    top: 135px;
    right: 0;

    width: 60%;

    @media (max-width: 899px) {
      position: relative;
      top: 0;
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      bottom: auto;
      margin-top: 55px;
    }

    img {
      display: block;

      width: 100%;
      height: auto;

      box-shadow: 5px 0px 25px 5px rgba($dark, .18);
    }
  }

  .split-text-image-slider {
    display: block;

    position: absolute;
    bottom: 175px;
    right: -90px;

    width: 60%;
    height: auto;

    border-radius: 10px;
    overflow: hidden;
    transform: skew($skewValueRevert);

    @media (max-width: 1149px) {
      width: 70%;
    }

    @media (max-width: 899px) {
      position: relative;
      width: 100%;
      max-width: 500px;
      bottom: auto;
      right: auto;
      margin: 0 auto;
      transform: none;
      border-radius: 0;
      margin-top: 50px;
    }

    .carousel-cell {
      position: relative;
      width: 100%;
      height: 32vw;
      max-height: 700px;
      margin: 0;
      padding: 0;

      &.is-selected {
        z-index: 5;
      }

      @media (max-width: 1149px) {
        min-height: 400px;
      }

      .carousel-cell-content,
      .bg-img {
        transform: skew($skewValue);

        @media (max-width: 899px) {
          transform: none;
        }
      }

      .carousel-cell-content {
        position: relative;
        z-index: 2;

        height: 100%;

        color: #fff;
        text-align: right;
      }

      .carousel-cell-content-wrapper {
        @extend .site-padding;

        position: absolute;
        top: 50%;
        right: 100px;
        transform: translate(0, -50%);

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        @media (max-width: 899px) {
          right: 0;
        }
      }

      .carousel-title {
        max-width: 800px;
        text-transform: uppercase;
        font-family: $fontTitleAlternate;
        font-weight: 800;
        font-size: 43px;
        color: $white;
        letter-spacing: 0.1em;

        text-shadow: 0px 0 20px rgba(38, 38, 38, .5);

        @media (max-width: 1149px) {
          max-width: 500px;
          font-size: 32px;
        }

        @media (max-width: 699px) {
          font-size: 23px;
        }
      }

      .site-btn {
        padding: 13px 25px;

        font-family: $fontText;
        font-size: 14px;
        color: $colorAccent1;
        background-color: $white;
        border: none;

        border-radius: 8px;
        transform: skew($skewValue);

        letter-spacing: 0.055em;
        text-transform: uppercase;

        @media (max-width: 899px) {
          transform: none;
        }

        .btn-text {
          display: block;
          transform: skew($skewValueRevert);

          @media (max-width: 899px) {
            transform: none;
          }
        }

        &:hover {
          background: #fff;
          color: $colorAccent1;
        }
      }

      .bg-img {
        position: absolute;
        top: -100px;
        right: -100px;
        bottom: -100px;
        left: -100px;

        transform: skew($skewValue);

        background-size: cover;
        background-position: center;

        @media (max-width: 899px) {
          transform: none;
        }
      }
    }

    .flickity-prev-next-button {
      border-radius: 5px;
      transform: skew(0);
      background-color: $white;

      @media (max-width: 899px) {
        transform: none;
      }

      &.previous {
        width: auto;
        height: auto;
        left: 50px;
        bottom: 40px;
        right: auto;
        top: auto;

        padding: 30px 40px;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        box-shadow: 0 0px 20px 0 rgba(38, 38, 38, 1);

        @media (max-width: 1149px) {
          padding: 20px 25px;
        }

        @media (max-width: 899px) {
          left: 20px;
        }

        svg {
          transform: scale(.5);
          path {
            d: path('M 5,50 97.5,5 97.5,95 Z');
          }
        }
      }

      &.next {
        width: auto;
        height: auto;
        top: auto;
        right: auto;
        left: 135px;
        bottom: 40px;

        padding: 30px 40px;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        box-shadow: 0 0px 20px 0 rgba(38, 38, 38, 1);

        @media (max-width: 1149px) {
          padding: 20px 25px;
          left: 110px;
        }

        @media (max-width: 899px) {
          left: 75px;
        }

        svg {
          transform: scale(.5);
          path {
            d: path('M 5,50 97.5,5 97.5,95 Z');
          }
        }
      }

      .flickity-button-icon {
        fill: $colorAccent1;
        transform: skew($skewValue);
      }
    }
  }

  &.is-right {
    // Default styles
    //
  }

  &.is-left {
    .split-text-image-content {
      align-items: flex-end;
      text-align: right;
    }

    .site-btn {
      align-self: flex-end;

      @media (max-width: 899px) {
        align-self: center;
      }
    }

    .split-text-image-image {
      right: auto;
      left: 0;
    }
  }

  &.is-grey {
    background-color: $grey;
  }
}

// --------------------------------------------------
// Block - Grid Image Text
// --------------------------------------------------

.block-grid-image-text {

  display: flex;
  flex-direction: column;

  padding-bottom: 150px;

  @media (max-width: 1245px) {
    flex-flow: row wrap;
  }

  @media (max-width: 767px) {
    display: block;
    padding-bottom: 0;
  }

  .lifestyle-title {
    @extend .medium-title;

    font-family: $fontText;
    min-height: 56px;
    color: $colorAccent2;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 20px;
    line-height: 1.3;

    @media (max-width: 767px) {
      min-height: inherit;
    }

    @media (max-width: 499px) {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  .lifestyle-desc {
    @extend .regular-text;
    width: 100%;
    max-width: 375px;
    min-height: 165px;
    margin: 0 auto;
    font-family: $fontTextLight;
    font-weight: 200;
    font-size: 16px;
    line-height: 1.5;

    p {
      padding: 0 20px;
    }

    @media (max-width: 1320px) {
      min-height: 185px;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      min-height: inherit;
      padding-bottom: 20px;
    }
  }
}

// --------------------------------------------------
// Lifestyle
// --------------------------------------------------

.lifestyle-row {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  width: 80%;
  margin: auto;
  box-shadow: 5px 0px 25px 10px rgba($dark, .18);

  @media (max-width: 767px) {
    display: block;
    width: 100%;
    box-shadow: none;
  }

  .lifestyle-row-img {
    position: relative;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -24px;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;
      border-bottom: 20px solid $colorAccent1;
      transform: rotate(180deg);
    }
    .lifestyle-img {
      display: block;
      width: 100%;
    }
  }

  .lifestyle-row-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 60px;
    background-color: $white;
    border-bottom: 10px solid $colorAccent1;

    @media (max-width: 1820px) {
      padding: 40px;
    }

    @media (max-width: 1699px) {
      padding: 20px 20px 30px 20px;
    }

    @media (max-width: 1520px) {
      padding: 15px;
    }

    @media (max-width: 1485px) {
      padding: 10px 10px 0 10px;
    }

    @media (max-width: 1450px) {
      padding: 0;
    }

    @media (max-width: 767px) {
      padding: 20px;
    }

    .lifestyle-desc {
      p {
        margin-bottom: 0;
      }
    }
  }

  .lifestyle-row-element {
    display: flex;
    flex-direction: column-reverse;
    width: calc(100% / 3);
    text-align: center;

    @media (max-width: 1199px) {
      width: 50%;
    }

    @media (max-width: 767px) {
      float: left;
      width: 100%;
      padding: 0 20px;

      &:last-child {
        margin-bottom: 45px;
      }
    }

    @media (max-width: 349px) {
      width: calc(90% - 10px);
    }

    &:nth-child(4),
    &:nth-child(6) {
      @media (min-width: 768px) and (max-width: 1199px) {
        .lifestyle-row-img {
          order: 2;
          width: 100%;
          &:before {
            top: inherit;
            bottom: 0;
            // border-bottom: 0;
            // border-top: 20px solid $colorAccent1;
            transform: inherit;
          }
        }

        .lifestyle-row-content {
          order: 1;
          border-top: 10px solid $colorAccent1;

          @media(max-width: 1199px) {
            border-bottom: 0;
          }
        }
      }

    }

    &:nth-child(2) {
      @media (min-width: 768px) {

        .lifestyle-row-img {
          order: 2;
          width: 100%;
          @media (max-width: 767px) {
            order: 1;
          }

          &:before {
            top: inherit;
            bottom: 0;

            // border-bottom: 0;
            // border-top: 20px solid $colorAccent1;
            transform: inherit;
          }
        }

        .lifestyle-row-content {
          order: 1;
          border-top: 10px solid $colorAccent1;
          border-bottom: 0;
        }
      }
    }

    &:nth-child(5) {
      @media (min-width: 1200px) {

        .lifestyle-row-img {
          order: 2;
          width: 100%;
          @media (max-width: 767px) {
            order: 1;
          }

          &:before {
            top: inherit;
            bottom: 0;
            // border-bottom: 0;
            // border-top: 20px solid $colorAccent1;
            transform: inherit;
          }
        }

        .lifestyle-row-content {
          order: 1;
          border-top: 10px solid $colorAccent1;
          border-bottom: 0;
        }
      }
    }
  }
}

// --------------------------------------------------
// Block - Image
// --------------------------------------------------

.block-image {
  margin: 0 auto;
  max-width: 845px;

  @media(max-width: 1280px){
    padding: 20px;
  }

  .image-title {
    @extend .big-title;
    max-width: 1024px;
    line-height: 1.1;
    margin: 0;
    padding: 0;
    text-align: center;
    color: $colorAccent2;
    margin-top: 40px;
  }

  .image-separator {
    display: block;
    height: 4px;
    width: 135px;
    margin: 35px auto;
    padding: 0;
    background-color: $colorAccent1;

    @media (max-width: 979px) {
      margin: 25px 0;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  .image-desktop {
    display: block;

    @media screen and (max-width: 899px) {
      display: none;
    }
  }

  .image-mobile {
    display: none;

    @media screen and (max-width: 899px) {
      display: block;
    }
  }
}

// --------------------------------------------------
// Block Grid Text
// --------------------------------------------------

.block-grid-text{
  width: 100%;
  margin: 25px 0;
  .row{
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 560px){
      padding: 0 20px;
    }

  }
  .block-grid-text-items{
    width: calc(100%/3);
    margin: 15px 0;
    display: inline-block;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    justify-content: flex-end;

    @media (min-width: 560px){
      padding: 0 15px;
    }

    img{
      display: block;
      width: 100%;
      max-width: 100px;
      margin: 0 auto;
    }
    h3{
      color:$colorAccent2;
      text-align: center;
    }
    p{
      @extend .regular-text;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
    }

    @media (max-width: 991px){
      width: 33%;
    }

    @media (max-width: 838px){
      width: 49%;
    }
    @media (max-width: 560px){
      width: 100%;
    }
  }
}

// --------------------------------------------------
// Block - Grid
// --------------------------------------------------

.block-grid {
  display: flex;
  padding: 60px 20px 0;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;
  max-width: 1240px;
  background: #fff;
  text-align: center;

  @media only screen and (max-width: 767px) {
    padding: 45px 20px;
  }

  .grid-text {
    padding: 45px 75px;

    @media only screen and (max-width: 767px) {
      padding: 45px 20px;
    }
  }

  .grid-items {
    display: flex;
    width: 100%;
    padding: 0 20px;
    margin-top: 35px;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    text-align: center;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      padding: 0 20px;
    }
  }

  .grid-item {
    width: 33%;
    padding: 10px 15px;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
      margin: 0 0 20px 0;
    }

    @media only screen and (max-width: 599px) {
      width: 100%;
    }

    .icon-holder {
      display: block;
      height: 90px;
      width: 90px;
      margin: 0 auto 30px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .small-title {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 1px;
      font-weight: 600;
      color: $dark;
      text-transform: uppercase;
      margin: 0 0 15px;
    }

    .regular-text p {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: .2px;
      font-weight: 400;
      color: #6D6D6D;
      margin: 0;
    }
  }
}

// --------------------------------------------------
// Posts
// --------------------------------------------------

.posts-list {
  width: 100%;
  padding: 75px 55px;
  background: #fff;
  text-align: center;

  @media only screen and (max-width: 1349px) {
    padding: 75px 45px;
  }

  @media only screen and (max-width: 1099px) {
    padding: 75px 25px;
  }

  @media only screen and (max-width: 767px) {
    padding: 25px 20px;
  }

  .medium-title {
    display: inline-block;
    margin: 0 auto;
    color: $grey;

    &:before,
    &:after {
      background: $grey;
    }

    @media only screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
}

// --------------------------------------------------
// Content - Pagination
// --------------------------------------------------

.content-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 75px;

  @media (max-width: 1099px) {
    padding: 25px 20px;
  }

  a,
  div {
    display: block;
    height: 35px;
    padding: 10px 15px;

    border-top: 1px $light solid;
    border-bottom: 1px $light solid;
    border-left: 1px $light solid;

    background: #fff;
    color: $dark;
    text-decoration: none;

    @extend .button-text;

    &.content-pagination-prev,
    &.content-pagination-next {
      span {
        display: block;
        width: 0;
        height: 0;
        border-top: 6.2px solid transparent;
        border-bottom: 6.2px solid transparent;
      }
    }

    &.content-pagination-prev {
      border-radius: 3px 0 0 3px;

      span {
        border-right: 6.2px solid $colorAccent1;

        &.is-disabled {
          border-right-color: $light;
        }
      }
    }

    &.content-pagination-next {
      border-right: 1px $light solid;
      border-radius: 0 3px 3px 0;

      span {
        border-left: 6.2px solid $colorAccent1;

        &.is-disabled {
          border-left-color: $light;
        }
      }
    }

    &:hover {
      color: $colorAccent1;
    }

    &.is-current {
      background: $colorAccent3;
      color: #fff;
      border-color: $colorAccent3;
    }

    &:last-child {
      border-right: 1px $light solid;
    }
  }
}

// --------------------------------------------------
// Block sub-menu
// --------------------------------------------------

.block-sub-menu {
  width: 100%;
  height: 70px;
  background-color: $colorAccent2;
  margin: 0;

  @media (max-width: 767px) {
    height: auto;
  }

  .sub-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    height: 100%;

    .menu {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      padding: 0;
      margin-top: 0;
      margin-bottom: 0;

      height: 100%;

      list-style: none;

      @media (max-width: 767px) {
        flex-direction: column;
        width: 100%;
      }

      .menu-link {
        @extend .regular-text;
        display: flex;
        align-items: center;

        margin-left: 15px;

        padding: 0 20px;
        height: 100%;
        max-width: 280px;

        color: $white;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;

        letter-spacing: 0.05em;

        &.is-active {
          border-bottom: 5px solid $colorAccent1;
        }

        &.menu-title {
          width: auto;
          max-width: unset;
          margin-left: 0;
          background-color: $colorAccent1;

          @media (max-width: 767px) {
            width: 100%;
          }
        }

        @media (max-width: 767px) {
          display: inline-block;
          padding: 12.5px 0;
          margin-left: 0;
          text-align: center;
        }

        a {
          color: $white;
          // margin-left: 25px;
          text-align: center;

          &:hover {
            color: $white;
          }
        }

        // @media (max-width: 1099px) {
        //   margin-right: 50px;
        // }
        // @media (max-width: 799px) {
        //   margin-right: 10px;
        // }
      }
    }
  }
}

// --------------------------------------------------
// Block team-grid
// --------------------------------------------------
.block-grid-team {
  .row {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 80px;

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 20px;
      max-width: 500px;
    }

    .visual {
      position: relative;
      display: flex;
      justify-content: flex-start;
      width: 50%;
      padding: 80px 0;

      @media (max-width: 767px) {
        flex-direction: column;
        width: calc(100% - 50px);
        padding: 5px 0;
        margin: 0 0;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -20%;
        height: 100%;
        width: 45%;
        background-color: rgba($colorAccent2, .93%);
        z-index: -1;

        @media (max-width: 767px) {
          // width: 100%;
          // height: 70%;
          position: relative;
        }
      }

      .title {
        color: $white;
      }

      .text {
        color: $white;
      }

      .visual-img {
        width: 70%;
        box-shadow: 5px 10px 30px 5px rgba(0,0,0,.1);

        @media (max-width: 767px) {
          width: 100%;
          margin: 0 0;
        }

        img {
          display: block;
          width: 100%;
          max-width: 100%;
          box-shadow: 5px 10px 30px 5px rgba(0,0,0,.1);
        }
      }
    }

    .content {
      width: 50%;
      padding: 50px;

      @media (max-width: 767px) {
        width: 100%;
        padding: 30px 20px;
        text-align: center;
        padding-top: 0;
      }

      .title {
        @extend .big-title;

        color: $colorAccent2;
        font-size: 46px;
        margin-bottom: 15px;
        text-align: right;

        @media (max-width: 767px) {
          text-align: left;
          font-size: 22px;
        }
      }

      .subtitle {
        @extend .small-title;

        font-family: $fontText;
        color: $dark;
        font-weight: 800;
        text-align: right;
        text-transform: uppercase;
        font-size: 25px;

        @media (max-width: 767px) {
          text-align: left;
          font-size: 16px;
          line-height: 1.2;
        }
      }

      .pattern {
        display: block;
        height: 4px;
        width: 135px;
        margin: 30px 0;
        margin-left: auto;
        padding: 0;
        background-color: $colorAccent1;

        @media (max-width: 979px) {
          margin: 25px 0;
        }
      }

      .text {
        @extend .regular-text;
        width: 100%;
        max-width: 450px;
        // text-align: right;
        // margin: 0 0 20px;
        margin-left: auto;
        @media (max-width: 767px) {
          text-align: left;
          margin-left: inherit;
        }
      }
    }

    &:nth-child(odd) {
      .visual {
        order: 2;
        justify-content: flex-end;

        @media (max-width: 767px) {
          order: 1;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 70%;
          right: 0;
          height: 100%;
          width: 45%;
          background-color: rgba($colorAccent1, .93%);
          z-index: -1;

          @media (max-width: 767px) {
            // width: 100%;
            // height: 70%;
            position: relative;
          }
        }
      }

      .content {
        order: 1;
        text-align: right;

        @media (max-width: 767px) {
          order: 2;
          text-align: center;
        }

        .title {
          text-align: left;
        }

        .subtitle {
          text-align: left;
        }

        .pattern {
          display: block;
          height: 4px;
          width: 135px;
          margin: 30px 0;
          // margin-left: auto;
          padding: 0;
          background-color: $colorAccent1;

          @media (max-width: 979px) {
            margin: 25px 0;
          }
        }

        .text {
          margin-left: inherit;
          text-align: left;
        }
      }
    }
  }
}


// --------------------------------------------------
// Block single-slider
// --------------------------------------------------

.block-single-slider {
  position: relative;
  width: 100%;
  // max-width: 1200px;
  padding: 50px 100px;
  margin: 0 auto;

  background-color: $grey;

  @media (max-width: 767px) {
    padding: 40px 20px;
    margin: 0 auto;
  }

  .single-slider-title {
    @extend .big-title;
    position: relative;
    max-width: 1024px;
    line-height: 1.1;
    margin: 0 auto;
    margin-bottom: 50px;
    padding: 0;
    text-align: center;
    color: $colorAccent2;

    z-index: 2;
  }

  .slider-shadow-title {
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%);
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    letter-spacing: .2em;
    text-transform: uppercase;
    font-size: 400px;
    font-family: $fontTitleAlternate;
    color: #fff;
    z-index: 0;

    @media (max-width: 1149px) {
      font-size: 302px;
    }

    @media (max-width: 899px) {
      display: none;
    }
  }

  .flickity-prev-next-button {
    border-radius: 5px;
    transform: skew($skewValue);
    background-color: $white;

    @media (max-width: 899px) {
      transform: none;
    }

    &.previous {

      width: auto;
      height: auto;
      left: -110px;
      bottom: 50%;
      right: auto;
      top: auto;

      padding: 30px 40px;

      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      box-shadow: 0 0px 20px 0 rgba(38, 38, 38, 0.12);

      @media (max-width: 1299px) {
        left: -50px;
      }

      @media (max-width: 1149px) {
        left: -10px;
      }

      @media (max-width: 699px) {
        // bottom: -60px;
        transform: scale(.65) translate(-10%, 50%);
        box-shadow: none;
        border: 1px solid rgba(0, 0, 0, .18);
      }

      svg {
        transform: scale(.5);
        path {
          d: path('M 5,50 97.5,5 97.5,95 Z');
        }
      }
    }

    &.next {
      width: auto;
      height: auto;
      top: auto;
      left: auto;
      right: -110px;
      bottom: 50%;

      padding: 30px 40px;

      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      box-shadow: 0 0px 20px 0 rgba(38, 38, 38, 0.12);

      @media (max-width: 1299px) {
        right: -50px;
      }

      @media (max-width: 1149px) {
        right: -10px;
      }

      @media (max-width: 699px) {
        // bottom: -60px;
        transform: scale(.65) translate(10%, 50%);
        box-shadow: none;
        border: 1px solid rgba(0, 0, 0, .18);
      }

      svg {
        transform: scale(.5);
        path {
          d: path('M 5,50 97.5,5 97.5,95 Z');
        }
      }
    }

    .flickity-button-icon {
      fill: $colorAccent1;
    }
  }

  .single-slider {
    width: 100%;
    z-index: 2;
    // background-color: $grey;

    min-height: 500px;

    @media (max-width: 1024px) {
      padding: 0 40px;
    }

    @media (max-width: 767px) {
      padding: 0;
    }

    .single-slider-wrap {
      position: relative;

      width: 60%;

      margin-left: 100px;
      margin-right: 100px;
      padding: 5px;
      background-color: $white;


      overflow: hidden;

      // &.is-selected {
      //   margin-left: 100px;
      //   margin-right: 100px;
      // }
      .single-slider-shadow {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 400px;
        box-shadow: 0 0px 20px 0 rgba(38, 38, 38, .25);
        border-radius: 12px;

        @media (max-width: 799px) {
          flex-direction: column;
          width: 100%;
          min-height: inherit;
          margin: 0;
        }
      }
      @media (max-width: 799px) {
        flex-direction: column;
        width: 100%;
        min-height: inherit;
        margin: 0;
      }

    }

    .single-slider-img {
      width: 35%;
      padding: 0 15px;

      @media (max-width: 799px) {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        padding: 20px;
      }

      img {
        display: block;
        margin: 0 auto;
      }

      .product-title {
        @extend .medium-title;
        text-align: center;
        text-transform: uppercase;
        font-family: $fontText;
        font-size: 22px;

        @media (max-width: 799px) {
          font-size: 18px;
        }
      }
    }

    .single-slider-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 65%;
      min-height: 400px;
      padding: 0 40px;
      background-color: $grey;

      @media (max-width: 799px) {
        display: block;
        width: 100%;
        min-height: inherit;
      }

      ul {
        padding: 0;
        // margin: 0;

        li {
          @extend .regular-text;
          line-height: 1.2;
          margin-bottom: 25px;
          font-size: 18px;

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }
      }


    }
  }

  .flickity-page-dots {
    bottom: 0;
    left: 0;
  }
}

// --------------------------------------------------
// Block - Grid-Card
// --------------------------------------------------

.grid-card {
  position: relative;
  width: 100%;
  background-color: $grey;
  padding-top: 55px;

  .shadow-title {
    position: absolute;
    top: auto;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 200%;
    margin: 0;
    padding: 0;
    text-align: center;
    letter-spacing: .12em;
    text-transform: uppercase;
    font-size: 330px;
    font-weight: 100;
    font-family: $fontTitleAlternate;

    z-index: 0;

    background: -webkit-linear-gradient(#fff, rgba(255, 255, 255, .15));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 1149px) {
      font-size: 202px;
      letter-spacing: .05em;
      top: 0;
    }
  }

  .grid-card-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    background-color: $grey;
    margin-left: 90px;
    margin-right: 90px;
    padding: 0 0 10px 0;


    &.no-text {
      .grid-element {
        .grid-bottom-content {
          display: none;
        }
      }
    }

    &.has-2-cards {
      .grid-element {
        max-width: 444px;
        margin: 80px;
        border-radius: 10px;

        .grid-top-content {
          padding: 50px 0px;

          .grid-title {
            margin-top: 25px;
            font-size: 22px;
          }
        }
        .grid-bottom-content {
          padding-bottom: 80px;

          .grid-text {
            border: inherit;
            padding: 20px;
            font-size: 18px;
            line-height: 1.2;

            .border-corner {
              display: none;
            }
          }
        }
      }
    }

    &.has-3-cards {
      .grid-element {
        margin-left: 105px;
        margin-right: 105px;
      }
    }

    .grid-element {
      width: 100%;
      max-width: 269px;
      margin-left: 40px;
      margin-right: 40px;

      margin-bottom: 50px;

      background-color: $white;
      border-radius: 15px;
      box-shadow: 5px 0px 25px 10px rgba($dark, .18);

      z-index: 2;

      @media (max-width: 1299px) {
        width: calc(80% / 3);
        margin-left: inherit;
      }

      @media (max-width: 1079px) {
        width: calc(80% / 2);
        margin-left: inherit;
      }

      @media (max-width: 799px) {
        width: 100%;
        margin-left: inherit;
      }

      .grid-top-content {
        padding: 20px 0  15px 0px;

        .grid-title {
          @extend .medium-title;

          font-family: $fontText;
          display: block;
          margin: 0 auto;
          font-size: 19px;
          margin-top: 15px;
          text-transform: uppercase;
          text-align: center;
          font-weight: 600;
          max-width: 220px;
          line-height: 1.2;
        }

        .grid-icon {
          display: block;
          margin: 0 auto;
        }
      }

      .grid-bottom-content {
        background-color: #fff;
        width: 100%;
        padding: 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .grid-text {
          position: relative;
          background: #fff;

          p {
            position: relative;
            padding: 40px 15px;
            text-align: center;
            margin: 0;
            border: 1px solid rgba($dark, .4);
            font-size: 15px;
            line-height: 1.2;
            font-weight: 200;
            font-family: $fontTextLight;
          }

          .border-corner {
            position: absolute;
            width: 25px;
            height: 25px;
            background: #fff;
            z-index: 1;

            &.tl {
              top: 0;
              left: 0;
              transform: translate(-50%, -50%);
            }

            &.tr {
              top: 0;
              right: 0;
              transform: translate(50%, -50%);
            }

            &.bl {
              bottom: 0;
              left: 0;
              transform: translate(-50%, 50%);
            }

            &.br {
              bottom: 0;
              right: 0;
              transform: translate(50%, 50%);
            }
          }
        }
      }
    }
  }
}


// --------------------------------------------------
// Block - Word-Grid
// --------------------------------------------------
.word-grid-block {
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;

  @media (max-width: 940px) {
    padding-bottom: 40px;
  }

  .block-introduction {
    background-color: $colorAccent2;
    padding-top: 70px;

    @media(max-width: 940px) {
      padding-top: 10vw;
    }

    .intro-title {
      color: $white;
    }
    .intro-content {
      color: $white;
    }
  }

  .word-grid {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    // margin: 0 auto;
    background-color: $colorAccent2;
    padding: 20px;

    padding-bottom: 70px;

    @media(max-width: 940px) {
      padding-bottom: inherit;
      padding: 0 20px;
    }

    .grid-letter {
      font-family: $fontTitle;
      width: 115px;
      text-align: center;
      font-size: 150px;
      font-weight: 700;
      color: rgba($colorAccent3, .6);

      @media(max-width: 940px) {
        font-size: 100px;
        height: 100px;
      }
    }

    .grid-element {
      display: flex;
      position: relative;
      width: 100%;
      max-width: 650px;
      max-height: 175px;
      margin-left: 115px;
      margin-right: 230px;

      background-color: $white;
      border-radius: 15px;

      z-index: 2;

      @media(max-width: 940px) {
        flex-direction: column;
        margin: 20px;
        max-height: inherit;
      }

      .grid-top-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30%;
        padding: 25px;

        @media(max-width: 940px) {
          width: 100%;
        }

        &::before {
          content: '';
          position: absolute;

          height: 35px;
          width: 35px;

          left: -10%;
          top: 50%;
          transform: translate(-25%,-50%);

          border-radius: 50%;
          background-color: rgba($colorAccent3, .6);

          @media(max-width: 940px) {
            display: none;
          }
        }

        .grid-title {
          @extend .medium-title;

          font-family: $fontText;
          display: block;
          margin: 0 auto;
          font-size: 19px;
          margin-top: 15px;
          text-transform: uppercase;
          text-align: center;
          font-weight: 600;
          max-width: 220px;
          line-height: 1.2;
          color: $colorAccent2;
        }

        .grid-icon {
          display: block;
          width: 70px;
          height: auto;
          margin: 0 auto;
        }
      }

      .grid-bottom-content {
        background-color: #fafafa;
        width: 70%;
        padding: 20px 15px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;


        @media(max-width: 940px) {
          width: 100%;
          border-top-right-radius: 0;
          border-bottom-left-radius: 15px;
        }

        .grid-text {
          position: relative;
          height: 100%;
          padding: 35px;
          border: 1px solid rgba($dark, .4);

          display: flex;
          align-items: center;
          justify-content: center;

          .text {
            position: relative;
            text-align: center;

            margin: 0;
            border: 1px solid rgba($dark, .4);
            font-size: 15px;
            line-height: 1.2;
            font-weight: 200;
            font-family: $fontTextLight;
            border: 0;
          }

          .border-corner {
            position: absolute;
            width: 25px;
            height: 25px;
            background: #fafafa;
            z-index: 1;

            &.tl {
              top: 0;
              left: 0;
              transform: translate(-50%, -50%);
            }

            &.tr {
              top: 0;
              right: 0;
              transform: translate(50%, -50%);
            }

            &.bl {
              bottom: 0;
              left: 0;
              transform: translate(-50%, 50%);
            }

            &.br {
              bottom: 0;
              right: 0;
              transform: translate(50%, 50%);
            }
          }
        }
      }
    }
  }
}
