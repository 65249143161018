// --------------------------------------------------
// Variables
// --------------------------------------------------

// Fonts
$fontTitleSerif: 'Tiempos Headline', serif;
$fontTitle: 'Tiempos Headline', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontTitleAlternate: 'Knockout', 'apercubold', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontText: 'apercubold', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontTextLight: 'aperculight', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Accents Colors
$colorAccent1: #ec2d2a;
$colorAccent2: #004995;
$colorAccent3: #aeaeae;

// Blocks and Text
$dark: #262626;
$grey: #f6f6f6;
$alternateGrey: #BAB9B9;
$light: #A0ACB9;
$lighter: #E5EAEF;
$alternateWhite: #f6f8fc;
$white : #fff;

$skewValue: 15deg;
$skewValueRevert: -15deg;
