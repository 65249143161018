.distribution {

  .block-items-slider {

    .items-cta {
      display: none;
    }

    .block-introduction {
      display: none;
    }

    .items-carousel {
      .items-carousel-cell {
        .items-main {
          width: 30%;
          margin: 50px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        .items-content-wrapper {
          background-color: $grey;

          .items-content-border {
            padding: 0;
            border: 0;

            .border-corner {
              display: none;
            }
          }

          .carousel-cell-title {
            position: absolute;
            top: -70px;
            left: 50%;

            transform: translate(-50%,0);

            width: 100%;

            margin-top: inherit;
            font-size: 20px;
            color: $colorAccent1;
          }
          .carousel-cell-logo {
            display: none;
          }
          .carousel-cell-text {
              @extend .regular-text;
              font-size: 15px;
          }
        }
        &.is-selected {
          .cell-effect-top,
          .cell-effect-bottom {
            display: none;
          }
        }
      }
    }
  }
}
