// --------------------------------------------------
// Header
// --------------------------------------------------
.homepage {
  .main-header {
    .header-container {
      display: flex;
    }
    .header-right {
      .header-right-logo {
        display: none;
      }
    }
  }

  .main-header .header-right .header-right-nav {
    padding-left: 0;
  }
}

.main-header {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0;
  z-index: 10;
  background: $white;

  .header-container {
    @extend .site-padding;

    display: none;
    align-items: center;
    justify-content: space-between;

    padding-top: 20px;
    padding-bottom: 20px;
  }

  .header-logo {
    margin-right: auto;
    img {
      max-width: 171px;
    }


    @media (max-width: 979px) {
      img {
        width: 175px;
      }
    }
  }

  .header-nav {
    display: flex;
    align-items: center;

    @media (max-width: 699px) {
      display: none;
    }

    .header-link {
      position: relative;
      font-family: $fontTextLight;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 1px;
      font-weight: 700;
      margin: 0 20px;
      color: $colorAccent3;

      transition: all 0.35s ease;

      &:hover {
        color: $colorAccent2;
      }

      @media (max-width: 1400px) {
        font-size: 14px;
        margin: 0 10px;
      }

      &.is-active:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: white;
      }
    }
  }

  .social-icons {
    i {
      font-size: 30px;
      color: $alternateGrey;
      transition: all ease 0.35s;

      &:hover {
        color: $colorAccent2;
      }
    }
  }

  .header-right {
    @extend .site-padding;

    display: flex;
    align-items: stretch;
    background-color: $grey;

    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;

    @media (max-width: 699px) {
      padding: 0;
      flex-direction: row-reverse;
      background-color: transparent;
    }

    .header-right-logo {
      position: absolute;
      left: 0;
      padding: 14px;
      background-color: $white;

      @media(max-width: 699px) {
        left: 50%;
        transform: translate(-50%,0);
      }

      .logo {
        display: block;
        width: 45px;
        height: auto;
        // max-height: 64px;
        // max-width: 90%;
        margin: 0 auto;
      }
    }
    .header-right-nav {
      display: flex;
      align-items: center;
      padding: 20px 0;
      padding-left: 35px;

      @media (max-width: 1400px) {
        padding-left: 55px;
      }

      @media (max-width: 767px) {
        display: none;
      }

      .header-right-link {
        position: relative;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0;
        font-weight: 500;
        text-transform: uppercase;
        margin: 0 40px 0 0;
        color: $colorAccent3;

        transition: all 0.35s ease;

        &:hover {
          color: $colorAccent2;
        }

        &.is-active {
          color: $colorAccent2;
        }

        @media (max-width: 1400px) {
          font-size: 13px;
          margin: 0 20px 0 0;
        }

        @media (max-width: 979px) {
          display: none;
        }

        &.is-active:after {
          content: '';
          position: absolute;
          bottom: -20px;
          left: -10px;
          right: -10px;
          width: auto;
          height: 4px;
          background-color: $colorAccent1;
        }
      }
    }

    .header-lang {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 1px;
      font-weight: 500;
      color: $colorAccent2;
      text-transform: uppercase;
      margin: 0 0 0 0;
      margin-left: auto;
      padding: 20px 30px;
      background: #fff;

      @media (max-width: 1400px) {
        font-size: 14px;
      }
    }

    .site-btn {
      background-color: $colorAccent1;
      color: $white;
      text-transform: uppercase;
      border-color: $colorAccent1;
      padding: 22px 45px;

      @media (max-width: 1400px) {
        padding: 20px 20px;
        font-size: 14px;
      }

      @media (max-width: 699px) {
        display: none;
      }
    }
  }
}


.header-menu {
  position: relative;
  width: 85px;
  cursor: pointer;
  background-color: $colorAccent2;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 16px;
    // left: 75px;
  }

  &:hover {
    &:after {
      background-color: $colorAccent2;
      transform: translateY(-50%) scale(1.2) rotate(180deg);
    }
  }
}

// --------------------------------------------------
// Mobile Burger
// --------------------------------------------------
.burger {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 999;

  &:hover {

    .lines {

      &:before{
        top: -10px;
      }

      &:after{
        top: 8px;
      }
    }
  }

  &.is-active {

    .lines {
      background: transparent;

      &:before {
        top: 0;
        background: $colorAccent1;
        transform: rotate(-45deg);
      }

      &:after {
        top: -2px;
        background: $colorAccent1;
        transform: rotate(45deg);
      }
    }
  }

  .lines {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: -1px;
    display: block;
    width: 50%;
    height: 3px;
    background: #fff;

    &:before,
    &:after {
      display: block;
      content: '';
      position: relative;
      top: -10px;
      width: 100%;
      height: 100%;
      background: #fff;
      transition: all 0.25s ease-out 0.1s;
    }

    &:after {
      top: 8px;
    }
  }
}
