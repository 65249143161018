.content-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  padding-bottom: 50px;

  a, div {
  	border: 0;
  }

  a,
  div {
    display: block;
    height: 35px;
    padding: 10px 15px;

    border-top: 1px gray solid;
    border-bottom: 1px gray solid;
    border-left: 1px gray solid;

    background: $light;
    color: lighten(#111, 50%);
    text-decoration:none;

    border-radius: 0;

    // @extend .button;

    &.content-pagination-prev,
    &.content-pagination-next {
      span {
        display: block;
        width: 0;
        height: 0;
        border-top: 6.2px solid transparent;
        border-bottom: 6.2px solid transparent;
      }
    }

    &.content-pagination-prev {
      border-radius: 3px 0 0 3px;

      span {
        border-right: 6.2px solid $colorAccent3;

        &.is-disabled {
          border-right: 6.2px solid lighten(#000, 65%);
        }
      }
    }

    &.content-pagination-next {
      border-right: 1px $grey solid;
      border-radius: 0 3px 3px 0;

      span {
        border-left: 6.2px solid $colorAccent3;

        &.is-disabled {
          border-left: 6.2px solid lighten(#000, 65%);
        }
      }
    }

    &:hover {
      color: $colorAccent3;
    }

    &.is-current {
      background: $colorAccent3;
      color: $white;
      border-color: $colorAccent3;
    }

    &:last-child {
      border-right: 1px $grey solid;
    }
  }
}