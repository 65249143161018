.site-form {

}

// GRID
.form-cols {
  display: flex;

  .form-col {
    width: 100%;
    padding: 0 25px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .form-col-half {
    width: 50%;
  }
}

.contact-form {
  width: 100%;
  max-width: 800px;
  padding: 70px 20px;
  margin: 0 auto;

  @media (max-width: 767px) {
    padding: 35px 20px;
  }
}

// Components
.supplier-form {
  max-width: 900px;
  margin: 0 auto;
  padding: 45px 0;

  @media screen and (max-width: 899px) {
    padding: 20px;
  }

  .site-btn {
    display: block;
    margin: 0 auto;
    width: 20%;
    align-self: center;
    line-height: 1.5;
    transform: skew(15deg);
    background-color: $colorAccent1;
    color: $white;
    border-color: $colorAccent1;
  }
}

.supplier-form-nav-wrapper {
  position: relative;
  width: 100%;
  margin: 45px 20px 20px 20px;

  .supplier-dashed-line {
    position: relative;
    width: 100%;
    max-width: 1000px;
    height: 55px;
    margin: 0 auto;
    background-image: url('/img/site/line-dashed-rounded.svg');
    background-repeat: repeat-x;
    background-position: center center;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 20px;
      height: 20px;
      border: 3px solid $colorAccent1;
      border-radius: 50%;
      background: white;
      transform: translate3d(0, -50%, 0);
    }

    &:after {
      right: 0;
    }
  }

  .dashed-line-fader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 800px;
    height: 25px;
    transform: translate3d(-50%, -50%, 0);
    background: -moz-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 18%, rgba(255,255,255,0) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 18%,rgba(255,255,255,0) 100%);
    background: radial-gradient(ellipse at center, rgba(255,255,255,1) 18%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
  }

  .supplier-form-nav-content {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: $white;

    .current-step {
      // font-family: Roboto-Medium;
      font-size: 40px;
      color: $colorAccent2;
      letter-spacing: 0;
      text-align: center;
    }

    .nav-step {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      margin: auto 80px;
      font-size: 26px;
      border-radius: 50%;
      background: #F7F7F7;

      @media screen and (max-width: 899px) {
        margin: auto;
      }

      i {
        color: $colorAccent1;
      }
    }
  }
}

.step-box {
  position: relative;
  display: none;
  z-index: -1;

  &.is-active {
    display: block;
    z-index: 2;
  }
}

.site-form {
  display: flex;
  flex-direction: column;

  .form-label {
    @extend .regular-text;
    display: block;
    font-weight: 500;
    font-family: $fontText;
    text-transform: uppercase;
    color: #8F8F8F;
    margin-bottom: 5px;
    line-height: 1;
    span {
      color: $colorAccent1;
    }

    &.smaller {
      font-size: 16px;
      text-transform: none;
      min-height: inherit;
    }
  }

  .form-input-wrapper {
    position: relative;
    width: 100%;
  }

  .form-select-wrapper {
    display: block;
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 45px auto;



    .form-input {
      display: block;
      z-index: 99;
      background-color: transparent;
    }

    i {
      display: block;
      position: absolute;
      right: 15px;
      top: 15px;
      // transform: translate3d(0, -25%, 0);
      color: $colorAccent1;
      z-index: 10;
    }
  }

  select {
    &.form-input {
      position: relative;
      text-transform: uppercase;
      -webkit-appearance: none;
    }
  }

  .form-input {
    display: block;
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 27px;
    background-color: white;
    border: 2px solid $colorAccent2;
    border-radius: 10px;
    font-size: 14px;
    line-height: 1;
    outline: none;

    &.error {
      border: 2px solid rgba($colorAccent1, 0.6);
      background-color: rgba($colorAccent1, 0.1);
    }

    &.file-section {
      display: flex;
      justify-content: space-between;

      @media (max-width: 630px) {
        flex-direction: column;
      }
    }

    &:focus {
      /**
       * Focused input styles here.
       */
      // outline: none;
      // box-shadow: 0.2rem 0.8rem 1.6rem rgba($colorAccent1, 0.12);
    }
  }

  .input-label {
    /**
    * The label gets absolute positioning, and a calculated set of  sizes,
    * positioning, and transforms, based on relativity to the container element
    * and input text styles.
    */
    display: block;
    position: absolute;
    bottom: 50%;
    left: 1px;
    padding-bottom: 4px;
    color: $dark;
    font-family: inherit;
    font-size: 18px;
    font-weight: inherit;
    line-height: 1;
    opacity: 0;
    transform: translate3d(0, 50%, 0) scale(1);
    transform-origin: 0 0;
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
    visibility 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
    z-index 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .form-input:placeholder-shown + .input-label {
    /**
     * While the placeholder is visible, we want to hide the label.
     */
     visibility: hidden;
     z-index: -1;
   }

   .form-input:not(:placeholder-shown) + .input-label,
   .form-input:focus:not(:placeholder-shown) + .input-label {
    /**
     * While the placeholder is not shown - i.e. the input has a value - we want
     * to animate the label into the floated position.
     */
     visibility: visible;
     z-index: 1;
     opacity: 1;
     transform: translate3d(0, -100%, 0) scale(0.8);
     transition: transform 300ms, visibility 0ms, z-index 0ms;
   }

  .form-textarea {
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 27px;
    border: 2px solid $colorAccent2;
    border-radius: 10px;
    font-size: 14px;
    line-height: 1;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-weight: 400;
    letter-spacing: .2px;
    // color: #6D6D6D;
    height: 200px;
    resize: none;
  }

  .form-section-title {
    margin-bottom: 35px;
    text-align: center;
  }

  .form-section-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .form-fields-group {
    width: 100%;
    max-width: 280px;
    margin: 0 5px;

    &.is-big {
      max-width: 900px;
    }

    .form-label {
      font-size: 16px;
      text-transform: none;
      min-height: 35px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &.half {
      width : calc((100% / 2) - 20px);

      &:last-child {
        margin-right: 0;
      }
    }

    .form-select-wrapper {
      max-width: unset;
      margin: 0;
    }
  }

  .form-radio-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 27px;
    border: 2px solid $colorAccent2;
    border-radius: 10px;

    .form-label {
      font-size: 16px;
      text-transform: none;
      min-height: inherit;
    }
  }

  /* The container */
  .form-radio-btn {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 18px;
    text-transform: none;
    line-height: 1.5;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover input ~ .checkmark {
      background-color: #ccc;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked ~ .checkmark {
        background-color: $colorAccent1;
      }

      &:checked ~ .checkmark:after {
        display: block;
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;
      border-radius: 50%;

      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $white;
        transform: translate3d(-50%, -50%, 0);
      }
    }
  }

  .site-btn {
    width: 20%;
    align-self: center;
    line-height: 1.5;
    transform: skew(15deg);
    background-color: $colorAccent1;
    color: $white;
    border-color: $colorAccent1;

    @media (max-width: 599px) {
      width: 100%;
    }

    &:hover {
      background-color: $white;
      color: $colorAccent1;
      border-color: $colorAccent2;
    }
    span {
      display: block;
      transform: skew(-15deg);
    }
  }
}

// --------------------------------------------------
// Hubspot Override
// --------------------------------------------------
.hbspt-form {
  form {
    &,
    *,
    & * {
      box-sizing: border-box;
    }

    .hs-form-field.field {
      box-sizing: border-box;
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0;
      margin-bottom: 0;

      label {
        font-size: 12px;
        letter-spacing: 0.035em;
        color: $dark;
        padding: 0;
        margin: 0;
      }

      .hs-error-msgs {
        padding: 0;
        margin: 0;

        li {
          padding: 0;
          margin: 0;
        }

        label {
          position: relative;
          top: 0;
          padding: 0;
          margin: 0;
          color: red;
        }
      }

      .input {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
      }

      input {
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;
        min-height: 54px;
        padding: 20px;
        margin-top: 4px;
        border: none;
        font-size: 14px;
        line-height: 1;
        outline: none;
        border-radius: 0;
        box-shadow: 0 0 40px 0 rgba(221, 221, 221, 0.5);
      }

      textarea {
        box-sizing: border-box;
        width: 100%;
        min-width: 100%;
        max-width: 631px;
        min-height: 135px;
        max-height: 230px;
        padding: 15px 20px;
        margin-bottom: 15px;
        border: 2px solid $lighter;
        font-size: 14px;
        line-height: 1;
        outline: none;
        box-shadow: none;

        @media (max-width: 1099px) {
          max-width: calc(100vw - 50px);
        }
      }
    }

    .actions {
      padding: 0;
      margin: 0;
    }

    .hs-button,
    .hs-button.primary.large {
      position: relative;
      top: 10px;
      padding: 12px 35px;
      margin: 0;
      // border-radius: 3px;
      background: $colorAccent1;
      letter-spacing: 0.055em;
      font-family: $fontTitleSerif;
      font-size: 16px;
      color: #fff;
      border: 1px solid $colorAccent1;
      box-shadow: none;

      cursor: pointer;

      transition: all 0.35s ease;

      &:hover {
        background: $colorAccent3;
        color: #fff;
        border-color: $colorAccent3;
      }
    }

    .hs_error_rollup {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translate3d(0, 100%, 0);
    }

    .hs-error-msgs.inputs-list {
      position: absolute;
      bottom: 8px;
      left: 0;
      right: 0;
      transform: translate3d(0, 100%, 0);
      line-height: 1;
    }
  }
}

.hubspot-contact {
  .hbspt-form form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .hs-form-field.field {
      width: 49%;

      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .hs-fieldtype-textarea.field.hs-form-field {
      width: 100%;
    }

    .hs-button,
    .hs-button.primary.large {
      position: relative;
      top: 10px;
      padding: 12px 35px;
      margin: 0;
      // border-radius: 3px;
      background: $colorAccent1;
      letter-spacing: 0.055em;
      font-family: $fontTitleSerif;
      font-size: 16px;
      color: #fff;
      border: 1px solid $colorAccent1;

      cursor: pointer;

      transition: all 0.35s ease;

      &:hover {
        background: $colorAccent3;
        color: #fff;
        border-color: $colorAccent3;
      }
    }

    .hs_error_rollup {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translate3d(0, 100%, 0);
    }

    .hs-error-msgs.inputs-list {
      position: absolute;
      bottom: 8px;
      left: 0;
      right: 0;
      transform: translate3d(0, 100%, 0);
      line-height: 1;
    }


    fieldset.form-columns-2,
    fieldset.form-columns-1 {
      width: 100%;
      position: relative;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      max-width: unset;
    }

    fieldset.form-columns-2 {
      .hs-form-field {
        float: left;
        width: calc(50% - 10px) !important;
        margin-right: 20px;

        &:last-child {
          margin: 0;
        }
      }
    }

    fieldset.form-columns-1 {
      .hs-form-field {
        width: 100%;
      }
    }

  }
}

.alert-success {
  // background: rgba(20, 181, 96, 0.5);
  width: 100%;
  text-align: center;
  padding: 15px 20px;
  border-radius: 3px;
  margin: 0 0 15px;
  font-weight: 600;
  color: green;
  font-size: 25px;
}

input#secretHoneypotApplication,
input#secretHoneypotSpontaneousApplication {
  display: none;
}
