// --------------------------------------------------
// Hero
// --------------------------------------------------
.slider-hero {
  position: relative;
  width: 100%;
  height: calc(100vh - 168px);
  max-height: 1000px;
  min-height: 800px;
  overflow: hidden;

  @media (max-width: 979px) {
    max-height: 800px;
  }

  @media (max-width: 899px) {
    height: auto;
    min-height: inherit;
    max-height: inherit;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.section-hero {
  position: relative;
  width: 100%;
  height: calc(100vh - 168px);
  max-height: 1000px;
  min-height: 800px;
  background: url('/img/site/ph1-hero.jpg');
  background-size: cover;
  background-color: $dark;
  background-position: center;

  &.is-selected {
    .section-hero-introduction {
      opacity: 1;
    }
  }

  &.hero-fix {
    .section-hero-introduction {
      opacity: 1;
    }
  }

  &.color-effect-grey,
  &.color-effect-blue {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, .45);
    }
  }

  &.color-effect-grey {
    &::before {
      background: rgba(0, 0, 0, .45);
    }
  }

  &.color-effect-blue {
    &::before {
      background: rgba($colorAccent2, .45);
    }
  }

  @media (max-width: 979px) {
    max-height: 800px;
  }

  @media (max-width: 899px) {
    height: auto;
    min-height: inherit;
    max-height: inherit;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .section-wrapper,
  .section-wrapper-content {
    height: 100%;
  }

  .section-hero-introduction {
    @extend .site-padding;

    position: absolute;
    top: 50%;
    left: -10px; // To counter border
    transform: translate(0, -50%);
    opacity: 0;
    width: 100%;
    max-width: 950px;
    background: rgba($dark,0.4);
    transition: opacity .7s ease-in-out;

    @media (max-width: 1070px) {
      max-width: 100%;
    }

    @media (max-width: 899px) {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }

    .section-hero-title{
      @extend .big-title;

      max-width: 875px;
      margin-top: 0;
      color: $white;

      @media (max-width: 979px) {
        width: 100%;
        margin: 0 auto;
        text-align: center;
      }
    }

    .section-hero-separator {
      display: block;
      height: 4px;
      width: 135px;
      background-color: $colorAccent1;

      @media (max-width: 979px) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
        margin-bottom: 25px;
      }
    }

    .section-hero-text {
      @extend .subtitle-text;

      max-width: 775px;
      margin-top: 45px;
      margin-bottom: 0;
      color: $white;
      line-height: 1.3;

      @media (max-width: 979px) {
        width: 100%;
        margin: 0 auto;
        text-align: center;
      }

      @media (max-width: 899px) {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

  .hero-pattern {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    z-index: 2;

    @media (max-width: 979px) {
      display: none;
    }
  }
}
