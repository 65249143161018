%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

/* Loader 2 */
.loader-container {
  position: relative;
  width: 100%;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  margin: 0px auto;
  width: 200px;
}
.loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px;
  }
}
@keyframes color {
  100%, 0% {
    stroke: #E1E1E1;
  }
  40% {
    stroke: #CCCCCC;
  }
  66% {
    stroke: #AEAEAE;
  }
  80%, 90% {
    stroke: #CCCCCC;
  }
}

.beacon-container {
  position: relative;
}

.beacon {
  width: 20px;
  height: 20px;
  background-color: #ef5d39;
  border-radius: 50%;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);

  &:after,
  &:before {
    position: absolute;
    display: block;
    background-color: transparent;
    transform-origin: 50% 50%;
  }

  &:after,
  &:before {
    content: '';
    top: 0;
    left: 0;
    border: 1px solid #ef5d39;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    transform: scale(1.3, 1.3);
  }

  &:before {
    animation: pulse 2s infinite;
  }

  &:after {
    animation: pulse 2s infinite .7s;
  }
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(2);
    opacity: 0; } }

@-moz-keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(2);
    opacity: 0; } }

@-o-keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(2);
    opacity: 0; } }

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(2);
    opacity: 0; } }
