// --------------------------------------------------
// Btns components
// --------------------------------------------------
.site-btn {
  display: inline-block;
  padding: 13px 25px;
  border: 2px solid $colorAccent2;
  background: $white;
  color: $colorAccent1;
  font-family: $fontText;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -.025em;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: .3s;
  transition-duration: .3s;

  -webkit-appearance: none;

  &:hover {
    background: $colorAccent2;
    color: #fff;
    opacity: .75;
  }

  &.is-skew {
    align-self: flex-start;
    border-radius: 7px;
    transform: skew($skewValue);

    @media (max-width: 899px) {
      align-self: center;
      transform: none;
    }

    span {
      display: block;
      transform: skew($skewValueRevert);

      @media (max-width: 899px) {
        transform: none;
      }
    }
  }

  &.is-outlined {
    padding: 15px 25px;
    border: 1px solid $colorAccent1;
    background: transparent;
    color: $colorAccent1;
    font-family: $fontTitleSerif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;

    &:hover {
      border-color: $colorAccent1;
      color: $colorAccent2;
      opacity: 1;
    }

    &.reverted-hover {
      &:hover {
        border-color: $dark;
        color: $dark;
        opacity: 1;
      }
    }
  }

  &.is-bigger {
    min-width: 200px;
    padding: 15px 25px;
    font-size: 18px;
  }

  &.is-alternate {
    border-color: $colorAccent3;
    background: $colorAccent3;
  }

  &.items-cta {
    background: transparent;
    border-radius: 5px;
    display: inline-block;
    width: auto;
    margin: 0 auto;
    transform: skew($skewValue);

    @media (max-width: 899px) {
      transform: none;
    }

    span {
      display: block;
      transform: skew($skewValueRevert);

      @media (max-width: 899px) {
        transform: none;
      }
    }

    &:hover {
      background: $colorAccent2;
      color: white;
    }

    &.bg-white {
      background-color: white;

      &:hover {
        background: $colorAccent2;
        color: white;
      }
    }
  }
}

.link-text-btn {
  padding: 15px 0;
  padding-right: 45px;
  border-bottom: 3px solid $colorAccent3;
  color: $colorAccent3;
  font-family: $fontTitleSerif;
  text-transform: uppercase;
}
