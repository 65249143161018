.blog-content-wrapper {
  .big-title {
    color: $colorAccent2;
  }
}

.careers-section {
  margin: 0 auto;
  width: 100%;
  max-width: 1520px;
  padding: 70px 20px;

  @media (max-width: 900px) {
    padding: 40px 20px;
  }

  .regular-text {

    max-width: 1024px;
    // font-size: 20px;
    font-family: $fontTextLight;
    // font-weight: 400;
    // line-height: 1.1;
    margin: 0 auto;
    margin-bottom: 100px;
    padding: 0;
    // color: $dark;

    p {
      font-family: $fontTextLight;
      font-size: 20px;
      line-height: 26px;
      // letter-spacing: .5px;
      color: #757C8B;
      font-weight: 400;
      margin: 0 0 30px;
      color: $dark;

      strong {
        color: $dark;
      }
    }

    // strong {
    //   font-family: $fontText;
    //   font-weight: 700;
    //   color: $dark;
    // }

    h1, h2, h3, h4, h5, h6 {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0 0 20px;
      color: $dark;

      @media(max-width: 900px) {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: .5px;
      }
    }

    ul {
      list-style: none;
      margin: 0 0 30px;
      padding: 0;

      li {
        @extend .regular-text;

        width: 100%;
        font-size: 18px;
        margin: 0 0 10px 0;
        padding: 0;
        text-align: left;

        &:before {
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: inherit;
          width: 1em;
          text-align: center;
          /* opacity: .8; */
          font-variant: normal;
          text-transform: none;
          content: "\E82B";
          margin-right: 20px;
          color: $colorAccent1;

          @media(max-width: 900px) {
            margin-right: 10px;
          }
        }
        a {
          color: $colorAccent2;
        }
      }
    }

    figure {
      margin: 0 0 30px;
    }

    img {
      max-width: 100%;
    }

    .video-container {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .big-title {
    @extend .big-title;
    max-width: 1024px;
    line-height: 1.1;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    color: $colorAccent2;

    margin-bottom: 70px;
    font-size: 32px;


    @media(max-width: 700px) {
      margin-bottom: 40px;
    }
  }

  .careers-intro {
    width: 100%;
    max-width: 800px;
    margin: 0 auto 40px;
    text-align: center;
  }

  .careers-listing {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #E6E6E6;
  }

  .careers-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E6E6E6;
    padding: 30px 20px;

    @media (max-width: 900px) {
      padding: 20px;
      flex-wrap: wrap;
    }

    &:nth-child(even) {
      background-color: #F0F0F0;
    }
  }

  .careers-link {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    color: $colorAccent1;
    border: 2px solid $colorAccent1;
    font-weight: 700;
    transition: all ease-out .2s;

    @media (max-width: 900px) {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
    }

    &:hover {
      background-color: $colorAccent1;
      color: white;
    }
  }

  .careers-infos {
    width: 550px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1180px) {
      width: 400px;
    }

    @media (max-width: 1023px) {
      flex-direction: column;
      width: 250px;
    }

    @media (max-width: 900px) {
      width: auto;
    }

    & > .careers-type, & > .careers-location {
      width: 50%;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: .2px;
      color: #6D6D6D;
      font-weight: 400;
      margin: 0;

      @media (max-width: 1023px) {
        margin: 5px 0;
        width: 100%;
      }
    }
  }

  .careers-name {
    flex-grow: 1;
    font-size: 26px;
    line-height: 24px;
    letter-spacing: 1px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: $fontText;

    @media (max-width: 900px) {
      width: 100%;
      margin: 0 0 20px;
    }
  }
}

.contact-form-wrapper {
  background-color: #fafafa;

  .contact-form {
    width: 100%;
    max-width: 1280px;
    padding: 70px 20px;
    margin: 0 auto;

    @media(max-width: 700px) {
      padding: 40px 20px;
    }

    .big-title {
      @extend .big-title;
      max-width: 1024px;
      line-height: 1.1;
      margin: 0;
      padding: 0;
      text-align: left;
      color: $colorAccent2;

      // margin-bottom: 80px;
      font-size: 32px;

      &.career-entry {
        margin-bottom: 80px;
      }

      @media(max-width: 700px) {
        margin-bottom: 40px;
      }
    }
    .regular-text {
      margin-bottom: 80px;
    }
    .site-form {
      .form-label {
        margin-bottom: 5px;
      }
    }
  }
}
