.site-menu {
  z-index: 9995;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 200vw;
  width: 100%;
  background-color: white;
  border-top: 8px solid $colorAccent2;
  border-bottom: 8px solid $colorAccent1;
  transform: scale(2);
  transition: all ease-out .5s;
  opacity: .5;

  &.is-opened {
    transform: translateX(-200vw) scale(1);
    opacity: 1;
  }

  .menu-bottom-right {
    width: 100%;
    max-width: 175px;
    margin-bottom: 20px;

    @media(max-width: 720px) {
      max-height: 60px;
    }
  }

  .menu-top-right {
    position: absolute;
    z-index: 9999;
    top: -8px;
    right: 0;

    .menu-top-right-links {
      display: flex;
    }

    .menu-close {
      width: 80px;
      height: 80px;
      background: $colorAccent2 url('/img/site/icon-close.svg') no-repeat center center / 28px;
      cursor: pointer;

      @media(max-width: 720px) {
        width: 60px;
        height: 60px;
        background-size: 20px;
      }
    }

    .language-link {
      display: inline-block;
      width: 80px;
      height: 80px;
      text-align: center;
      line-height: 80px;
      text-transform: uppercase;
      background-color: white;

      @media(max-width: 720px) {
        display: none;
      }
    }
  }

  .menu-background-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 420px);
    background: url('/img/site/blog-hero-default.svg') no-repeat center center / cover;

    @media(max-width: 1023px) {
      width: calc(100% - 360px);
    }
  }

  .menu-main {
    position: absolute;
    z-index: 9999;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 450px;
    background-color: white;
    box-shadow: 4px 0 18px 0 rgba(black, .14);

    @media(max-width: 1023px) {
      max-width: 360px;
    }

    @media(max-width: 720px) {
      max-width: 100%;
      z-index: 9998;
    }

    .menu-main-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 40px;
      background: url('/img/site/menu-bg-left.svg') no-repeat right 25px center / 367px auto;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      @media(max-width: 720px) {
        background-size: 200px auto;
        padding: 20px;
        justify-content: flex-start;
      }
    }

    .logo-full {
      @media(max-width: 720px) {
        margin-bottom: 40px;

        img {
          height: 40px;
        }
      }
    }

    .main-nav {
      display: flex;
      flex-direction: column;

      @media(max-width: 720px) {
        display: block;
        margin-bottom: 40px;
      }

      .menu-link {
        display: block;
        position: relative;
        font-size: 30px;
        line-height: 33px;
        font-family: $fontText;
        margin: 0 0 40px;
        color: $alternateGrey;
        text-transform: uppercase;
        transition: color ease-out .2s;
        cursor: pointer;

        @media(max-width: 1023px) {
          font-size: 24px;
          line-height: 28px;
          margin: 0 0 40px;
        }

        @media(max-width: 720px) {
          font-size: 18px;
          line-height: 24px;
          margin: 0 0 10px;
          color: $colorAccent2;
        }

        &.has-submenu:after {
          content: '';
          position: absolute;
          top: 50%;
          right: -16px;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          background: url('/img/site/icon-right-chevron-red.svg') no-repeat center center / contain;

          @media(max-width: 720px) {
            display: none;
          }
        }

        &:hover {
          color: $colorAccent2;
        }
      }

      .menu-submenu {
        display: none;
        padding-left: 20px;
        border-left: 4px solid $colorAccent1;
        margin-bottom: 10px;

        @media(max-width: 720px) {
          display: block;
        }

        .menu-sublink {
          display: block;
          color: $alternateGrey;
          padding: 5px 0;
        }
      }
    }

    .second-nav {
      display: flex;
      flex-direction: column;
      //
      // @media(max-width: 720px) {
      //   flex-direction: column;
      // }

      .menu-link {
        display: block;
        position: relative;
        font-size: 30px;
        line-height: 33px;
        font-family: $fontText;
        // margin: 0 0 70px;
        color: $alternateGrey;
        text-transform: uppercase;
        font-weight: 900;
        margin-right: 40px;

        @media(max-width: 1023px) {
          font-size: 24px;
          line-height: 28px;
          margin: 0 0 40px;
        }

        @media(max-width: 720px) {
          margin-bottom: 10px;
        }

        &:hover {
          color: $colorAccent2;
        }
      }
    }
  }

  .submenu {
    position: absolute;
    z-index: 9997;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 420px;
    background: #F7F7F7 url('/img/site/menu-bg-right.svg') no-repeat left 25px center / 367px auto;
    transition: transform ease-out .2s;

    @media(max-width: 1023px) {
      max-width: 360px;
    }

    @media(max-width: 720px) {
      display: none;
    }

    &.is-opened {
      z-index: 9998;
      transform: translateX(100%);
    }

    .submenu-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px 40px 40px 70px;

      .menu-link {
        position: relative;
        font-size: 30px;
        line-height: 33px;
        font-family: $fontText;
        margin: 0 0 70px;
        color: $colorAccent2;
        text-transform: uppercase;
        transition: color ease-out .2s;
        cursor: pointer;

        @media(max-width: 1023px) {
          font-size: 24px;
          line-height: 28px;
          margin: 0 0 40px;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: -16px;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          background: url('/img/site/icon-right-chevron-blue.svg') no-repeat center center / contain;
          opacity: 0;
          transition: opacity ease-out .2s;
        }

        &:hover {
          color: $colorAccent1;

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  .only-mobile {
    display: none;

    @media(max-width: 720px) {
      display: block;
    }
  }
}
